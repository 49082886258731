import React, {FunctionComponent} from "react"
import SectionHeading from "../../../tech/section/section-heading.component"
import {useNavigate} from "react-router-dom"
import {InvestorWithDetailsType} from "../../investor/investor.type"
import tableStyles from "../../../tech/table/table.module.css"
import {INVESTOR, SYNDICATE_MEMBER_REMOVE} from "../../../paths"
import ActionLink from "../../../tech/link/action.component"
import {SyndicateType} from "../syndicate.type"
import {createInvestmentsLink} from "../../investment/investment.util"

type SyndicateMembersOverviewProps = {
    syndicate: SyndicateType
    members: InvestorWithDetailsType[]
}

const SyndicateMembersOverview: FunctionComponent<SyndicateMembersOverviewProps> = ({
    syndicate,
    members
}) => {
    const navigate = useNavigate()
    return (
        <>
            <SectionHeading
                title="Members"
                subTitle={`${members.length} syndicate members`}
            />
            {members.length > 0 ? (
                <div className={tableStyles.tableWrapper}>
                    <table className={tableStyles.tablePointer}>
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Member via investor groups</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {members.map((member, i) => {
                            const c = () => navigate(INVESTOR(member.investor.id!))
                            const investorGroupIdsOfMember = member.investor.investorGroups.map(ig => ig.id)
                            const memberViaInvestorGroups = syndicate.investorGroups.filter(ig => investorGroupIdsOfMember.includes(ig.id))
                            return (
                                <tr key={i}>
                                    <td onClick={c}>
                                        {member.investor.id}
                                    </td>
                                    <td onClick={c}>
                                        {member.investor.person?.firstName} {member.investor.person?.lastName}
                                    </td>
                                    <td onClick={c}>
                                        {member.investor.person?.contact.email || member.investor.user?.email}
                                    </td>
                                    <td
                                        onClick={c}
                                        className={tableStyles.cellList}
                                    >
                                        <ul>
                                            {memberViaInvestorGroups.map((investorGroup, index) => (
                                                <li key={`member-via-investor-group-${index}`}>
                                                    {investorGroup.name}
                                                </li>
                                            ))}
                                        </ul>
                                    </td>
                                    <td>
                                        <ActionLink
                                            text={"Go to investor"}
                                            link={INVESTOR(member.investor.id!)}
                                        />
                                        <ActionLink
                                            text={"Go to investments for this investor"}
                                            link={createInvestmentsLink(member.investor.id!)}
                                        />
                                        <ActionLink
                                            text={"Remove from syndicate"}
                                            link={SYNDICATE_MEMBER_REMOVE(syndicate.id!, member.investor.id!)}
                                        />
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </div>
            ) : (
                <>No members assigned to this syndicate yet.</>
            )}
        </>
    )
}

export default SyndicateMembersOverview