import React, {FunctionComponent, useEffect, useRef, useState} from "react"
import {DocumentIcon} from "@heroicons/react/24/outline"
import {DateTime} from "luxon"
import {FileType} from "../file.type"
import {formatBytes} from "../../../tech/format/bytes.util"
import styles from "./preview.module.sass"

type FilePreviewProps = {
    file: FileType
    maxHeight: number
}

const FilePreview: FunctionComponent<FilePreviewProps> = ({ file, maxHeight }) => {
    const imageRef = useRef<HTMLImageElement>(null)
    const [width, setWidth] = useState<number | undefined>()
    const [height, setHeight] = useState<number | undefined>()

    useEffect(() => {
        setWidth(imageRef.current?.naturalWidth)
        setHeight(imageRef.current?.naturalHeight)
    }, [imageRef])

    return (
        <div className={styles.preview}>
            {showDocumentIcon(file) ? (
                <div className={styles.icon}>
                    <DocumentIcon className={styles.documentIcon}/>
                </div>
            ) : (
                <div className={styles.image}>
                    <img
                        alt={file.id}
                        src={file.url}
                        ref={imageRef}
                        style={{ maxHeight }}
                    />
                </div>
            )}
            {widthAndHeightAvailable(width, height) ? (
                <div className={styles.dimensions}>
                    <small>
                        Width: {width}px | Height: {height}px
                        {file.bytes !== undefined && " | Size: " + formatBytes(file.bytes)}
                    </small>
                </div>
            ) : file.bytes !== undefined ? (
                <div className={styles.dimensions}>
                    <small>
                        Size: {formatBytes(file.bytes)}
                    </small>
                </div>
            ) : (
                <></>
            )}
            <div>
                <small>Uploaded {DateTime.fromJSDate(file.created).toLocaleString(DateTime.DATETIME_MED)}</small>
            </div>
        </div>
    )
}

export default FilePreview

function showDocumentIcon(file: FileType): boolean {
    return file.url === undefined
        || file.mimeType === "PDF"
}

function widthAndHeightAvailable(width: number | undefined, height: number | undefined) {
    return width !== undefined
        && width > 0
        && height !== undefined
        && height > 0
}