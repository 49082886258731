import {FunctionComponent} from "react"
import {NavLink} from "react-router-dom"
import styles from "./sidebar.module.css"

type SidebarLinkProps = {
    path: string
    title: string
    toggleSidebar: () => void
}

const SidebarLink: FunctionComponent<SidebarLinkProps> = ({ path, title, toggleSidebar }) => {
    return (
        <NavLink
            to={path}
            className={({ isActive }) => (isActive ? styles.sidebarLinkActive : styles.sidebarLink)}
            onClick={toggleSidebar}
        >
            <span>{title}</span>
        </NavLink>
    )
}

export default SidebarLink