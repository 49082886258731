import {DealType} from "../deal/deal.type"
import {DocumentType} from "../document/document.type"
import {InvestorType} from "../investor/investor.type"
import {CurrencyOpenApi} from "../../generated"
import {InvestmentFeesType} from "./fee/fees.type"
import {ExchangeRateType} from "../exchange-rate/exchange-rate.type"

export class InvestmentType {
    public id: string
    public created: Date
    public modified: Date
    public amount: number
    public amountWithPlacementFee: number
    public amountWithPlacementFeeAndExchangeFee: number | undefined
    public amountWithPlacementFeeAndExchangeFeeAtExchangeRate: number | undefined
    public currency: CurrencyOpenApi | undefined
    public agreed: boolean
    public signed: boolean
    public paymentInstructionsSent: boolean
    public paid: boolean
    public deal: DealType
    public documents: DocumentType[]
    public investor: InvestorType
    public fees: InvestmentFeesType
    public exchangeRate: ExchangeRateType | undefined

    constructor({
        id,
        created,
        modified,
        amount,
        amountWithPlacementFee,
        amountWithPlacementFeeAndExchangeFee,
        amountWithPlacementFeeAndExchangeFeeAtExchangeRate,
        currency,
        agreed,
        signed,
        paymentInstructionsSent,
        paid,
        deal,
        documents,
        investor,
        fees,
        exchangeRate
    } : {
        id: string
        created: Date
        modified: Date
        amount: number
        amountWithPlacementFee: number
        amountWithPlacementFeeAndExchangeFee: number | undefined
        amountWithPlacementFeeAndExchangeFeeAtExchangeRate: number | undefined
        currency: CurrencyOpenApi | undefined
        agreed: boolean
        signed: boolean
        paymentInstructionsSent: boolean
        paid: boolean
        deal: DealType
        documents: DocumentType[]
        investor: InvestorType
        fees: InvestmentFeesType
        exchangeRate: ExchangeRateType | undefined
    }) {
        this.id = id
        this.created = created
        this.modified = modified
        this.amount = amount
        this.amountWithPlacementFee = amountWithPlacementFee
        this.amountWithPlacementFeeAndExchangeFee = amountWithPlacementFeeAndExchangeFee
        this.amountWithPlacementFeeAndExchangeFeeAtExchangeRate = amountWithPlacementFeeAndExchangeFeeAtExchangeRate
        this.currency = currency
        this.agreed = agreed
        this.signed = signed
        this.paymentInstructionsSent = paymentInstructionsSent
        this.paid = paid
        this.deal = deal
        this.documents = documents
        this.investor = investor
        this.fees = fees
        this.exchangeRate = exchangeRate
    }

    public get amountFormatted(): string {
        return this.amount.toLocaleString("en", {
            currency: this.deal.currency,
            style: 'currency',
            maximumFractionDigits: 2
        })
    }

    public get amountWithPlacementFeeFormatted(): string {
        return this.amountWithPlacementFee.toLocaleString("en", {
            currency: this.deal.currency,
            style: 'currency',
            maximumFractionDigits: 2
        })
    }

    public get amountWithPlacementFeeAndExchangeFeeFormatted(): string | undefined {
        return this.amountWithPlacementFeeAndExchangeFee?.toLocaleString("en", {
            currency: this.deal.currency,
            style: 'currency',
            maximumFractionDigits: 2
        })
    }

    public get amountWithPlacementFeeAndExchangeFeeAtExchangeRateFormatted(): string | undefined {
        return this.amountWithPlacementFeeAndExchangeFeeAtExchangeRate?.toLocaleString("en", {
            currency: this.currency,
            style: 'currency',
            maximumFractionDigits: 2
        })
    }
}
