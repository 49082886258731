import React, {FunctionComponent} from "react"
import {SubmitHandler, useForm} from "react-hook-form"
import formStyles from "../../tech/form/form.module.css"
import FormError from "../../tech/form/error.component"
import {WaitlistEntryType} from "./waitlist.type"

type WaitlistEntryFormProps = {
    waitlistEntry?: WaitlistEntryType
    onSubmit: SubmitHandler<WaitlistEntryType>
    submitText: string
}

const WaitlistEntryForm: FunctionComponent<WaitlistEntryFormProps> = ({ waitlistEntry, onSubmit, submitText }) => {
    const { register, handleSubmit, formState: { errors } } = useForm<WaitlistEntryType>({
        defaultValues: waitlistEntry
    })

    return (
        <form
            className={formStyles.form}
            onSubmit={handleSubmit(onSubmit)}
        >
            <input type="hidden" value={waitlistEntry?.id} {...register("id")} />

            <label>
                <span>First name</span>
                <input
                    type="text"
                    placeholder="First name"
                    {...register("firstName", {
                        required: "First name is required.",
                        minLength: { value: 2, message: "Too short." }
                    })}
                />
                <FormError field="firstName" errors={errors} />
            </label>

            <label>
                <span>Last name</span>
                <input
                    type="text"
                    placeholder="Last name"
                    {...register("lastName", {
                        required: "Last name is required.",
                        minLength: { value: 2, message: "Too short." }
                    })}
                />
                <FormError field="lastName" errors={errors} />
            </label>

            <label>
                <span>Email</span>
                <input
                    type="text"
                    placeholder="Email"
                    {...register("contact.email", {
                        required: "Email is required.",
                        pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "Invalid email."
                        }
                    })}
                />
                <FormError field="contact.email" errors={errors} />
            </label>

            <label>
                <span>Phone</span>
                <input
                    type="text"
                    placeholder="Phone"
                    {...register("contact.phone", {
                        pattern: {
                        value: /^(\+|00)[1-9][0-9 \-().]{7,32}$/,
                        message: "Please enter a valid phone number. According to international format it should start with a plus sign (+)."
                    }})}
                />
                <FormError field="contact.phone" errors={errors} />
            </label>

            <label>
                <span>LinkedIn Profile</span>
                <input
                    type="text"
                    placeholder="LinkedIn"
                    {...register("contact.linkedIn", {
                        pattern: {
                            value: /^(https?:\/\/)?(www\.)?linkedin\.com\/(in|pub)\/[a-zA-Z0-9_-]+(\/)?$/,
                            message: "Please enter a valid LinkedIn profile URL."
                        }
                    })}
                />
                <FormError field="linkedIn" errors={errors} />
            </label>

            <label>
                <span>Referral Code</span>
                <input
                    type="text"
                    placeholder="Referral Code"
                    {...register("referralCode", {
                        pattern: {
                            value: /^[a-zA-Z0-9]{12}$/,
                            message: "Please enter a valid referral code consisting of twelve alphanumeric characters."
                        }
                    })}
                />
                <FormError field="referralCode" errors={errors} />
            </label>

            <div className={formStyles.checkbox}>
                <div className={formStyles.checkboxInner}>
                    <input
                        id="confirmed"
                        type="checkbox"
                        {...register("confirmed")}
                    />
                    <label htmlFor="confirmed">
                        Confirmed?
                    </label>
                </div>
                <FormError field="confirmed" errors={errors} />
            </div>

            <div className={formStyles.checkbox}>
                <div className={formStyles.checkboxInner}>
                    <input
                        id="aiStatusDeclared"
                        type="checkbox"
                        {...register("aiStatusDeclared", {
                            required: "Accredited investor status declaration must be set"
                        })}
                    />
                    <label htmlFor="aiStatusDeclared">
                        Accredited investor status declared
                    </label>
                </div>
                <FormError field="aiStatusDeclared" errors={errors} />
            </div>

            <button
                type="submit"
                className={formStyles.submitButton}
            >
                {submitText}
            </button>
        </form>
    )
}

export default WaitlistEntryForm