import {ImageType} from "./image.type"
import {ImageOpenApi} from "../../generated"

export function mapOpenApiToImage(image: ImageOpenApi): ImageType {
    return {
        fileId: image.fileId,
        fileIdentifier: image.fileIdentifier,
        url: image.url
    }
}

export function mapImageToOpenApi(image: ImageType): ImageOpenApi {
    return {
        fileId: image.fileId,
        fileIdentifier: image.fileIdentifier,
        url: image.url
    }
}