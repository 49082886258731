import React, {FunctionComponent} from "react"
import {DateTime} from "luxon"
import {MANUAL_RECONCILIATION_LOG} from "../../../paths"
import tableStyles from "../../../tech/table/table.module.css"
import {ManualReconciliationLogType} from "./manual-reconciliation-log.type"
import {useNavigate} from "react-router-dom"
import {formatAmountWithCurrency} from "../../../tech/format/format.util"

type ManualReconciliationLogTableProps = {
    logs: ManualReconciliationLogType[]
    showInvestment: boolean
}

const ManualReconciliationLogTable: FunctionComponent<ManualReconciliationLogTableProps> = ({
    logs,
    showInvestment
}) => {
    const navigate = useNavigate()
    return (
        <div className={tableStyles.tableWrapper}>
            <table className={tableStyles.tablePointer}>
                <thead>
                <tr>
                    <th>Received</th>
                    <th>Amount</th>
                    {showInvestment && (
                        <th>Investment</th>
                    )}
                    <th>ID</th>
                </tr>
                </thead>
                <tbody>
                {logs.map((log, i) => (
                    <tr
                        key={i}
                        onClick={() => navigate(MANUAL_RECONCILIATION_LOG(log.id!))}
                    >
                        <td>{DateTime.fromJSDate(log.received).toLocaleString(DateTime.DATE_MED)}</td>
                        <td>{formatAmountWithCurrency(log.amount, log.investment.deal.currency)}</td>
                        {showInvestment && (
                            <td>
                                {log.investment.deal.name.completeOnboarding}
                                {" "}-{" "}
                                {log.investment.investor.person?.firstName} {log.investment.investor.person?.lastName}
                            </td>
                        )}
                        <td>{log.id}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    )
}

export default ManualReconciliationLogTable