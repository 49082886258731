import React, {useContext, useEffect, useState} from "react"
import {SubmitHandler} from "react-hook-form"
import {useNavigate} from "react-router-dom"
import Section from "../../tech/section/section.component"
import SectionHeading from "../../tech/section/section-heading.component"
import SyndicateForm from "./form.component"
import {FetchClient} from "../../tech/fetch/client"
import FetchContext from "../../tech/fetch/fetch.context"
import {SYNDICATE} from "../../paths"
import LoadingDots from "../../tech/loading/dots/dots.component"
import Alert from "../../tech/alert/alert.component"
import {AlertType} from "../../tech/alert/type.enum"
import {normalizeValues} from "../../tech/form/form.util"
import {InvestorGroupType} from "../investor-group/investor-group.type"
import {UserType} from "../user/user.type"
import {UserSortOpenApi} from "../../generated"
import {SyndicateType} from "./syndicate.type"

const SyndicateNew = () => {
    const fetchClient = useContext<FetchClient>(FetchContext)
    const navigate = useNavigate()
    const [state, setState] = useState<"LOADING" | "LOADED" | "ERROR">("LOADING")
    const [investorGroups, setInvestorGroups] = useState<InvestorGroupType[]>([])
    const [managers, setManagers] = useState<UserType[]>([])

    useEffect(() => {
        const fetch = async () => {
            try {
                const [
                    fetchedInvestorGroups,
                    fetchedManagers,
                ] = await Promise.all([
                    fetchClient.investorGroupApi.getAll(),
                    fetchClient.userApi.getAllPaginated(
                        UserSortOpenApi.CreatedAscending,
                        { role: "SYNDICATE_MANAGER" }
                    ),
                ])
                setInvestorGroups(fetchedInvestorGroups)
                setManagers(fetchedManagers.elements)
                setState("LOADED")
            } catch (err) {
                console.error(err)
                setState("ERROR")
            }
        }
        fetch()
    }, [fetchClient])

    const onSubmit: SubmitHandler<SyndicateType> = async (syndicate) => {
        setState("LOADING")
        try {
            const savedSyndicate = await fetchClient.syndicateApi.create(normalizeValues(syndicate))
            navigate(SYNDICATE(savedSyndicate.id!))
        } catch (err) {
            console.error(err)
            setState("ERROR")
        }
    }

    return (
        <>
            {state === "LOADING" && <LoadingDots/>}
            {state === "ERROR" && (
                <Alert
                    type={AlertType.ERROR}
                    text="Failed to save syndicate."
                />
            )}
            {(state === "LOADED" || state === "ERROR") && (
                <Section>
                    <SectionHeading title="Create new syndicate"/>
                    <SyndicateForm
                        investorGroups={investorGroups}
                        managers={managers}
                        onSubmit={onSubmit} submitText="Create syndicate"
                    />
                </Section>
            )}
        </>
    )
}

export default SyndicateNew