import {CompanyEmbeddableOpenApi, CompanyOpenApi} from "../../generated"
import {CompanyEmbeddableType, CompanyType} from "./company.type"
import {mapDealToOpenApi, mapOpenApiToDeal} from "../deal/deal.mapper"
import {mapCompanyContactToOpenApi, mapOpenApiToCompanyContact} from "./contact/contact.mapper"

export function mapOpenApiToCompanyEmbeddable(company: CompanyEmbeddableOpenApi): CompanyEmbeddableType {
    return {
        legalName: company.legalName,
        url: company.url,
    }
}

export function mapCompanyEmbeddableToOpenApi(company: CompanyEmbeddableType): CompanyEmbeddableOpenApi {
    return {
        legalName: company.legalName,
        url: company.url,
    }
}

export function mapOpenApiToCompany(company: CompanyOpenApi): CompanyType {
    return {
        id: company.id,
        created: company.created,
        modified: company.modified,
        legalName: company.legalName,
        url: company.url,
        contacts: company.contacts.map(c => mapOpenApiToCompanyContact(c)),
        deals: company.deals.map(d => mapOpenApiToDeal(d))
    }
}

export function mapCompanyToOpenApi(company: CompanyType): CompanyOpenApi {
    return {
        id: company.id,
        created: company.created,
        modified: company.modified,
        legalName: company.legalName,
        url: company.url,
        contacts: company.contacts.map(c => mapCompanyContactToOpenApi(c)),
        deals: company.deals.map(d => mapDealToOpenApi(d))
    }
}