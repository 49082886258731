import React, {FunctionComponent} from "react"
import {CollectionAccountType} from "./collection-account.type"
import tableStyles from "../../../tech/table/table.module.css"
import {DateTime} from "luxon"
import {mapCurrencyToString} from "../../currency/currency.mapper"
import BankDetails from "./bank-details.component"
import styles from "./collection-account.module.css"

type CollectionAccountProps = {
    collectionAccount?: CollectionAccountType
}

const CollectionAccount: FunctionComponent<CollectionAccountProps> = ({ collectionAccount }) => {
    if (collectionAccount === undefined) {
        return <>No collection account created yet.</>
    }

    return (
        <div className={tableStyles.tableWrapper}>
            <table className={tableStyles.table}>
                <tbody>
                <tr>
                    <td><strong>Id</strong></td>
                    <td>{collectionAccount.id}</td>
                </tr>
                <tr>
                    <td><strong>Wallex Id</strong></td>
                    <td>{collectionAccount.wallexId}</td>
                </tr>
                <tr>
                    <td><strong>Wallex Account Id</strong></td>
                    <td>{collectionAccount.wallexAccountId}</td>
                </tr>
                <tr>
                    <td><strong>Created</strong></td>
                    <td>{DateTime.fromJSDate(collectionAccount.created).toLocaleString(DateTime.DATETIME_MED)}</td>
                </tr>
                <tr>
                    <td><strong>Created at Wallex</strong></td>
                    <td>{DateTime.fromJSDate(collectionAccount.wallexCreated).toLocaleString(DateTime.DATETIME_MED)}</td>
                </tr>
                <tr>
                    <td><strong>Modified</strong></td>
                    <td>{DateTime.fromJSDate(collectionAccount.modified).toLocaleString(DateTime.DATETIME_MED)}</td>
                </tr>
                <tr>
                    <td><strong>Currency</strong></td>
                    <td>{mapCurrencyToString(collectionAccount.currency)}</td>
                </tr>
                <tr>
                    <td><strong>Description</strong></td>
                    <td>{collectionAccount.description}</td>
                </tr>
                <tr>
                    <td><strong>Reference</strong></td>
                    <td>{collectionAccount.reference || "-"}</td>
                </tr>
                <tr>
                    <td><strong>Purpose</strong></td>
                    <td>{collectionAccount.purpose}</td>
                </tr>
                <tr>
                    <td><strong>Bank details</strong></td>
                    <td className={styles.bankDetailsList}>
                        {collectionAccount.bankDetailsList.map((d, index) => (
                            <BankDetails
                                bankDetails={d}
                                key={index}
                            />
                        ))}
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    )
}

export default CollectionAccount