import React, {useContext, useEffect, useState} from "react"
import {useNavigate, useParams} from "react-router-dom"
import {FetchClient} from "../../../tech/fetch/client"
import FetchContext from "../../../tech/fetch/fetch.context"
import {SYNDICATE_MEMBERS_INVITATIONS} from "../../../paths"
import LoadingDots from "../../../tech/loading/dots/dots.component"
import Alert from "../../../tech/alert/alert.component"
import {AlertType} from "../../../tech/alert/type.enum"
import Section from "../../../tech/section/section.component"
import Button from "../../../tech/button/button.component"
import {ButtonStyle} from "../../../tech/button/style.enum"
import {SyndicateInvitationType} from "./invitation.type"

const SyndicateInvitationDelete = () => {
    let { syndicateId, invitationId } = useParams()
    const navigate = useNavigate()
    const fetchClient = useContext<FetchClient>(FetchContext)
    const [state, setState] = useState<"LOADING" | "LOADED" | "ERROR">("LOADING")
    const [invitation, setInvitation] = useState<SyndicateInvitationType>()

    useEffect(() => {
        const fetch = async () => {
            try {
                setInvitation(await fetchClient.syndicateApi.getInvitation(syndicateId!, invitationId!))
                setState("LOADED")
            } catch (err) {
                console.error(err)
                setState("ERROR")
            }
        }
        fetch()
    }, [fetchClient, syndicateId, invitationId])

    const del = async () => {
        setState("LOADING")
        try {
            await fetchClient.syndicateApi.deleteInvitation(syndicateId!, invitationId!)
            navigate(SYNDICATE_MEMBERS_INVITATIONS(syndicateId!))
        }
        catch (err) {
            console.error(err)
            setState("ERROR")
        }
    }

    return (
        <>
            {state === "LOADING" && <LoadingDots/>}
            {state === "ERROR" && (
                <Alert
                    type={AlertType.ERROR}
                    text="Unknown error."
                />
            )}
            {(state === "LOADED" || state === "ERROR") && invitation && (
                <Section>
                    <div className="mb-5">
                        Do you really want to delete the invitation to syndicate <strong>{invitation.syndicate.name}</strong> for <strong>{invitation.email}</strong> with id <strong>{invitation.id}</strong>?
                    </div>
                    <Button
                        title="Delete permanently"
                        style={ButtonStyle.DELETE}
                        onClick={del}
                        type="button"
                    />
                </Section>
            )}
        </>
    )
}

export default SyndicateInvitationDelete