import {CollectionPaymentTypeOpenApi, CollectionReceiverPaymentTypeOpenApi, CollectionStatusOpenApi, CountryOpenApi, CurrencyOpenApi} from "../../generated"
import {AccountNumberType} from "./account/collection-account.type"

export class CollectionType {
    public id: string
    public wallexId: string
    public created: Date
    public modified: Date
    public amount: CollectionAmountType
    public customerReference?: string
    public creditedAt: Date
    public currency: CurrencyOpenApi
    public fee: number
    public payment: CollectionPaymentType
    public receiver: CollectionReceiverType
    public sender: CollectionSenderType
    public status: CollectionStatusOpenApi

    constructor({
        id,
        wallexId,
        created,
        modified,
        amount,
        customerReference,
        creditedAt,
        currency,
        fee,
        payment,
        receiver,
        sender,
        status
    } : {
        id: string
        wallexId: string
        created: Date
        modified: Date
        amount: CollectionAmountType
        customerReference?: string
        creditedAt: Date
        currency: CurrencyOpenApi
        fee: number
        payment: CollectionPaymentType
        receiver: CollectionReceiverType
        sender: CollectionSenderType
        status: CollectionStatusOpenApi
    }) {
        this.id = id
        this.wallexId = wallexId
        this.created = created
        this.modified = modified
        this.amount = amount
        this.customerReference = customerReference
        this.creditedAt = creditedAt
        this.currency = currency
        this.fee = fee
        this.payment = payment
        this.receiver = receiver
        this.sender = sender
        this.status = status
    }

    public get amountActualFormatted(): string {
        return this.amount.actual.toLocaleString("en", {
            currency: this.currency,
            style: 'currency',
            minimumFractionDigits: 0,
            maximumFractionDigits: 2
        })
    }

    public get amountOriginFormatted(): string {
        return this.amount.origin.toLocaleString("en", {
            currency: this.currency,
            style: 'currency',
            minimumFractionDigits: 0,
            maximumFractionDigits: 2
        })
    }

    public get feeFormatted(): string {
        return this.fee.toLocaleString("en", {
            currency: this.currency,
            style: 'currency',
            minimumFractionDigits: 0,
            maximumFractionDigits: 2
        })
    }
}

export type CollectionAmountType = {
    actual: number
    origin: number
}

export type CollectionPaymentType = {
    type: CollectionPaymentTypeOpenApi
    details?: string
}

export type CollectionReceiverType = {
    accountHolderName: string
    accountNumber: AccountNumberType
    bankName: string
    paymentType: CollectionReceiverPaymentTypeOpenApi
}

export type CollectionSenderType = {
    name: string
    address?: string
    country?: CountryOpenApi
    routingCode?: string
}