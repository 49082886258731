/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CollectionDeviationStateOpenApi } from './CollectionDeviationStateOpenApi';
import {
    CollectionDeviationStateOpenApiFromJSON,
    CollectionDeviationStateOpenApiFromJSONTyped,
    CollectionDeviationStateOpenApiToJSON,
} from './CollectionDeviationStateOpenApi';
import type { CollectionDeviationTypeOpenApi } from './CollectionDeviationTypeOpenApi';
import {
    CollectionDeviationTypeOpenApiFromJSON,
    CollectionDeviationTypeOpenApiFromJSONTyped,
    CollectionDeviationTypeOpenApiToJSON,
} from './CollectionDeviationTypeOpenApi';
import type { CollectionOpenApi } from './CollectionOpenApi';
import {
    CollectionOpenApiFromJSON,
    CollectionOpenApiFromJSONTyped,
    CollectionOpenApiToJSON,
} from './CollectionOpenApi';
import type { InvestmentOpenApi } from './InvestmentOpenApi';
import {
    InvestmentOpenApiFromJSON,
    InvestmentOpenApiFromJSONTyped,
    InvestmentOpenApiToJSON,
} from './InvestmentOpenApi';
import type { ManualReconciliationLogOpenApi } from './ManualReconciliationLogOpenApi';
import {
    ManualReconciliationLogOpenApiFromJSON,
    ManualReconciliationLogOpenApiFromJSONTyped,
    ManualReconciliationLogOpenApiToJSON,
} from './ManualReconciliationLogOpenApi';

/**
 * 
 * @export
 * @interface CollectionDeviationOpenApi
 */
export interface CollectionDeviationOpenApi {
    /**
     * 
     * @type {string}
     * @memberof CollectionDeviationOpenApi
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof CollectionDeviationOpenApi
     */
    created: Date;
    /**
     * 
     * @type {Date}
     * @memberof CollectionDeviationOpenApi
     */
    modified: Date;
    /**
     * 
     * @type {CollectionDeviationStateOpenApi}
     * @memberof CollectionDeviationOpenApi
     */
    state: CollectionDeviationStateOpenApi;
    /**
     * 
     * @type {CollectionDeviationTypeOpenApi}
     * @memberof CollectionDeviationOpenApi
     */
    type: CollectionDeviationTypeOpenApi;
    /**
     * 
     * @type {CollectionOpenApi}
     * @memberof CollectionDeviationOpenApi
     */
    collection?: CollectionOpenApi;
    /**
     * 
     * @type {ManualReconciliationLogOpenApi}
     * @memberof CollectionDeviationOpenApi
     */
    manualReconciliationLog?: ManualReconciliationLogOpenApi;
    /**
     * 
     * @type {InvestmentOpenApi}
     * @memberof CollectionDeviationOpenApi
     */
    investment: InvestmentOpenApi;
}

/**
 * Check if a given object implements the CollectionDeviationOpenApi interface.
 */
export function instanceOfCollectionDeviationOpenApi(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "modified" in value;
    isInstance = isInstance && "state" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "investment" in value;

    return isInstance;
}

export function CollectionDeviationOpenApiFromJSON(json: any): CollectionDeviationOpenApi {
    return CollectionDeviationOpenApiFromJSONTyped(json, false);
}

export function CollectionDeviationOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): CollectionDeviationOpenApi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'created': (new Date(json['created'])),
        'modified': (new Date(json['modified'])),
        'state': CollectionDeviationStateOpenApiFromJSON(json['state']),
        'type': CollectionDeviationTypeOpenApiFromJSON(json['type']),
        'collection': !exists(json, 'collection') ? undefined : CollectionOpenApiFromJSON(json['collection']),
        'manualReconciliationLog': !exists(json, 'manualReconciliationLog') ? undefined : ManualReconciliationLogOpenApiFromJSON(json['manualReconciliationLog']),
        'investment': InvestmentOpenApiFromJSON(json['investment']),
    };
}

export function CollectionDeviationOpenApiToJSON(value?: CollectionDeviationOpenApi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'created': (value.created.toISOString()),
        'modified': (value.modified.toISOString()),
        'state': CollectionDeviationStateOpenApiToJSON(value.state),
        'type': CollectionDeviationTypeOpenApiToJSON(value.type),
        'collection': CollectionOpenApiToJSON(value.collection),
        'manualReconciliationLog': ManualReconciliationLogOpenApiToJSON(value.manualReconciliationLog),
        'investment': InvestmentOpenApiToJSON(value.investment),
    };
}

