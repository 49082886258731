import {InvestorType} from "./investor.type"
import {isEmpty} from "../../tech/form/form.util"

export function getInvestorName(investor: InvestorType): string {
    return `${investor.person?.firstName} ${investor.person?.lastName}`
}

export function hasAnyPersonData(investor: InvestorType) {
    return doesHaveAnyNonEmptyLeaf(investor.person)
}

function doesHaveAnyNonEmptyLeaf(obj: any): boolean {
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            if (typeof obj[key] === "object") {
                return doesHaveAnyNonEmptyLeaf(obj[key])
            } else if (key !== "email" && key !== "salutation" && !isEmpty(obj[key])) {
                return true
            }
        }
    }
    return false
}