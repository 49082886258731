import React, {useContext, useEffect, useState} from "react"
import {useNavigate, useParams} from "react-router-dom"
import {FetchClient} from "../../tech/fetch/client"
import FetchContext from "../../tech/fetch/fetch.context"
import LoadingDots from "../../tech/loading/dots/dots.component"
import Alert from "../../tech/alert/alert.component"
import {AlertType} from "../../tech/alert/type.enum"
import Section from "../../tech/section/section.component"
import SectionHeading from "../../tech/section/section-heading.component"
import DealGeographyForm from "./form.component"
import {SubmitHandler} from "react-hook-form"
import {DEAL_GEOGRAPHIES, DEAL_GEOGRAPHY_DELETE} from "../../paths"
import Button from "../../tech/button/button.component"
import {ButtonStyle} from "../../tech/button/style.enum"
import {normalizeValues} from "../../tech/form/form.util"
import {DealGeographyType} from "./deal-geography.type"

const DealGeographyEdit = () => {
    let { id } = useParams()
    const navigate = useNavigate()
    const fetchClient = useContext<FetchClient>(FetchContext)
    const [state, setState] = useState<"LOADING" | "LOADED" | "ERROR">("LOADING")
    const [dealGeography, setDealGeography] = useState<DealGeographyType>()

    useEffect(() => {
        const fetch = async () => {
            try {
                setDealGeography(await fetchClient.dealGeographyApi.get(id!))
                setState("LOADED")
            } catch (err) {
                console.error(err)
                setState("ERROR")
            }
        }
        fetch()
    }, [fetchClient, id])

    const onSubmit: SubmitHandler<DealGeographyType> = async (dealGeography) => {
        setState("LOADING")
        try {
            await fetchClient.dealGeographyApi.update(id!, normalizeValues(dealGeography))
            navigate(DEAL_GEOGRAPHIES)
        } catch (err) {
            console.error(err)
            setState("ERROR")
        }
    }

    return (
        <>
            {state === "LOADING" && <LoadingDots/>}
            {state === "ERROR" && (
                <Alert
                    type={AlertType.ERROR}
                    text="Unknown error."
                />
            )}
            {(state === "LOADED" || state === "ERROR") && dealGeography && (
                <Section>
                    <SectionHeading
                        title={`Edit deal geography: ${dealGeography.name}`}
                        button={<Button
                            title="Delete"
                            style={ButtonStyle.DELETE}
                            onClick={() => navigate(DEAL_GEOGRAPHY_DELETE(id!))}
                            type="button"
                        />}
                    />
                    <DealGeographyForm
                        geography={dealGeography}
                        onSubmit={onSubmit}
                        submitText="Save deal geography"
                    />
                </Section>
            )}
        </>
    )
}

export default DealGeographyEdit