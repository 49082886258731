import React, {useContext, useEffect, useMemo, useState} from "react"
import {useNavigate, useSearchParams} from "react-router-dom"
import {FetchClient} from "../../tech/fetch/client"
import FetchContext from "../../tech/fetch/fetch.context"
import LoadingDots from "../../tech/loading/dots/dots.component"
import Section from "../../tech/section/section.component"
import SectionHeading from "../../tech/section/section-heading.component"
import Button from "../../tech/button/button.component"
import {ButtonStyle} from "../../tech/button/style.enum"
import {WAITLIST_ENTRY, WAITLIST_ENTRY_NEW} from "../../paths"
import tableStyles from "../../tech/table/table.module.css"
import Alert from "../../tech/alert/alert.component"
import {AlertType} from "../../tech/alert/type.enum"
import {WaitlistEntryType} from "./waitlist.type"
import Actions from "../../tech/actions/actions.component"
import {PaginatedType} from "../../tech/actions/pagination/pagination.type"
import {emptyPaginated, getPagination} from "../../tech/actions/pagination/pagination.util"
import {assembleSortOptions, getCurrentSortOption} from "./sort.util"
import {assembleFilterOptions, getCurrentFilter, mapToWaitlistFiltersOpenApi} from "./filter.util"

const WaitlistOverview = () => {
    const ENTRIES_PER_PAGE = 100
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const fetchClient = useContext<FetchClient>(FetchContext)
    const [state, setState] = useState<"LOADING" | "LOADED" | "ERROR">("LOADING")
    const [entries, setEntries] = useState<PaginatedType<WaitlistEntryType>>(emptyPaginated())

    const sortOptions = assembleSortOptions()
    const currentSortOption = useMemo(
        () => getCurrentSortOption(sortOptions, searchParams),
        [sortOptions, searchParams]
    )

    const filters = useMemo(() => assembleFilterOptions(), [])
    const currentFilter = useMemo(
        () => getCurrentFilter(filters, searchParams),
        [filters, searchParams]
    )

    const pagination = useMemo(() => getPagination(ENTRIES_PER_PAGE, searchParams), [searchParams])

    useEffect(() => {
        const fetch = async () => {
            try {
                setState("LOADING")
                setEntries(await fetchClient.waitlistApi.getAllPaginated(
                    currentSortOption.id,
                    mapToWaitlistFiltersOpenApi(currentFilter),
                    pagination
                ))
                setState("LOADED")
            } catch (err) {
                console.error(err)
                setState("ERROR")
            }
        }
        fetch()
    }, [fetchClient, currentFilter, currentSortOption.id, pagination])

    return (
        <Section>
            <SectionHeading
                title="Waitlist"
                subTitle={`${entries.elements.length} entries`}
                button={<Button
                    title="Add entry"
                    style={ButtonStyle.SECONDARY}
                    onClick={() => navigate(WAITLIST_ENTRY_NEW)}
                    type={"button"}
                />}
            />
            <Actions
                filter={{
                    current: currentFilter,
                    filters
                }}
                pagination={{
                    label: "waitlist entries",
                    elementsPerPage: ENTRIES_PER_PAGE,
                    numberElements: entries.total
                }}
                sort={{
                    current: currentSortOption,
                    options: sortOptions
                }}
            >
                {state === "LOADING" && <LoadingDots/>}
                {state === "LOADED" && (
                    <div className={tableStyles.tableWrapper}>
                        <table className={tableStyles.tablePointer}>
                            <thead>
                            <tr>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Phone</th>
                                <th>LinkedIn Profile</th>
                                <th>Confirmed?</th>
                                <th>Referral Code</th>
                                <th>ID</th>
                            </tr>
                            </thead>
                            <tbody>
                            {entries.elements.map((entry, i) => {
                                return (
                                    <tr
                                        key={i}
                                        onClick={() => navigate(WAITLIST_ENTRY(entry.id!))}
                                    >
                                        <td>
                                            {entry.firstName} {entry.lastName}
                                        </td>
                                        <td>
                                            {entry.contact.email}
                                        </td>
                                        <td>
                                            {entry.contact.phone}
                                        </td>
                                        <td>
                                            {entry.contact.linkedIn}
                                        </td>
                                        <td>
                                            {entry.confirmed ? "yes" : "no"}
                                        </td>
                                        <td>
                                            {entry.referralCode}
                                        </td>
                                        <td>
                                            {entry.id}
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                    </div>
                )}
                {state === "ERROR" && (
                    <Alert
                        type={AlertType.ERROR}
                        text="Failed to load waitlist entries"
                    />
                )}
            </Actions>
        </Section>
    )
}

export default WaitlistOverview