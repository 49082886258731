import React, {FunctionComponent, useContext, useEffect, useState} from "react"
import {KycLogType} from "./kyc-log.type"
import {FetchClient} from "../../../tech/fetch/client"
import FetchContext from "../../../tech/fetch/fetch.context"
import {FileDownloadLinkType, FileType} from "../../file/file.type"
import LoadingDots from "../../../tech/loading/dots/dots.component"
import tableStyles from "../../../tech/table/table.module.css"
import Alert from "../../../tech/alert/alert.component"
import {AlertType} from "../../../tech/alert/type.enum"
import {DateTime} from "luxon"
import ActionLink from "../../../tech/link/action.component"

type KycLogsTableProps = {
    kycLogs: KycLogType[]
}

const KycLogsTable: FunctionComponent<KycLogsTableProps> = ({ kycLogs }) => {
    const fetchClient = useContext<FetchClient>(FetchContext)
    const [state, setState] = useState<"LOADING" | "LOADED" | "ERROR">("LOADING")
    const [fileDownloadLinks, setFileDownloadLinks] = useState<FileDownloadLinkType[]>([])

    useEffect(() => {
        const fetch = async () => {
            try {
                const files = kycLogs.flatMap(l => l.files).filter((f): f is FileType => !!f)
                setFileDownloadLinks(await Promise.all(files.map(f => fetchClient.fileApi.getDownloadLink(f.id))))
                setState("LOADED")
            } catch (err) {
                console.error(err)
                setState("ERROR")
            }
        }
        fetch()
    }, [fetchClient, kycLogs])

    if (state === "LOADING") return <LoadingDots/>
    if (state === "LOADED") {
        if (kycLogs.length === 0) {
            return<>No KYC logs available.</>
        }

        return (
            <div className={tableStyles.tableWrapper}>
                <table className={tableStyles.table}>
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Type</th>
                        <th>Created</th>
                        <th>Investor</th>
                        <th>Num. of files</th>
                        <th>Files</th>
                    </tr>
                    </thead>
                    <tbody>
                    {kycLogs.map((kycLog, i) => (
                        <tr key={i}>
                            <td>{kycLog.id}</td>
                            <td>{kycLog.type}</td>
                            <td>{DateTime.fromJSDate(kycLog.created).toLocaleString(DateTime.DATETIME_MED)}</td>
                            <td>{kycLog.investor.person?.firstName} {kycLog.investor.person?.lastName}</td>
                            <td>{kycLog.files.length}</td>
                            <td>
                                {kycLog.files.length > 0 && (
                                    <ul>
                                        {kycLog.files.length > 0 && kycLog.files.map((file, i) => {
                                            const fileLink = fileDownloadLinks.find(l => file.id === l.fileId)!.link
                                            return (
                                                <li key={i}>
                                                    <ActionLink
                                                        text={`Download file (ID: ${file.id})`}
                                                        link={fileLink}
                                                        blank
                                                    />
                                                </li>
                                            )
                                        })}
                                    </ul>
                                )}
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        )
    }
    return (
        <Alert
            type={AlertType.ERROR}
            text="Unknown error."
        />
    )
}

export default KycLogsTable