import React, {FunctionComponent} from "react"
import {ArrowDownTrayIcon} from "@heroicons/react/24/outline"
import {FileType} from "../file.type"
import styles from "./download.module.sass"

type FileDownloadProps = {
    file: FileType
}

const FileDownload: FunctionComponent<FileDownloadProps> = ({ file }) => {
    if (file.url === undefined) {
        return <></>
    }

    return (
        <div
            className={styles.download}
            onClick={() => openInNewTab(file.url!)}
        >
            <ArrowDownTrayIcon className={styles.icon}/>
        </div>
    )
}

export default FileDownload

function openInNewTab(url: string) {
    window.open(url, "_blank", "noreferrer")
}