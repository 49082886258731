import React, {FunctionComponent} from "react"
import {Controller, SubmitHandler, useForm} from "react-hook-form"
import Select from "react-select"
import {DateTime} from "luxon"
import formStyles from "../../tech/form/form.module.css"
import FormError from "../../tech/form/error.component"
import {UserType} from "../user/user.type"
import {InvestorType} from "./investor.type"
import {mapSalutationToString} from "../person/person.type"
import {countries} from "../address/country/country.type"
import {DealGroupType} from "../deal-group/deal-group.type"
import {InvestorGroupType} from "../investor-group/investor-group.type"
import {hasAnyPersonData} from "./investor.util"
import {isEmpty} from "../../tech/form/form.util"

type InvestorFormProps = {
    investor?: InvestorType
    usersWithoutInvestor?: UserType[]
    dealGroups: DealGroupType[]
    investorGroups: InvestorGroupType[]
    onSubmit: SubmitHandler<InvestorType>
    submitText: string
}

const InvestorForm: FunctionComponent<InvestorFormProps> = ({
    investor,
    usersWithoutInvestor,
    dealGroups,
    investorGroups,
    onSubmit,
    submitText
}) => {
    const { control, register, handleSubmit, formState: { errors } } = useForm<InvestorType>({
        defaultValues: investor
    })

    return (
        <form
            className={formStyles.form}
            onSubmit={handleSubmit(onSubmit)}
        >
            <input type="hidden" value={investor?.id} {...register("id")} />

            {investor?.user ? (
                <div className="pb-3">
                    <strong>User:</strong>{" "}
                    {investor.user.email ? investor.user.email : investor.user.userId}
                </div>
            ) : usersWithoutInvestor && (
                <>
                    <h3>Eligible users without an already assigned investor</h3>
                    <label>
                        <span>User</span>
                        <Controller
                            name="user"
                            control={control}
                            render={({field: {onChange, value}}) => {
                                const options = usersWithoutInvestor.map(user => ({
                                    label: user.email ? user.email : user.userId,
                                    value: user
                                }))
                                return (
                                    <Select
                                        options={options}
                                        value={options.find(u => u.value === value)}
                                        onChange={val => onChange(val?.value)}
                                    />
                                )
                            }}
                        />
                    </label>
                </>
            )}

            <div className="pb-3">
                <strong>Stripe Customer ID:</strong>{" "}
                {investor?.stripeCustomerId || "-"}
                <input type="hidden" value={investor?.stripeCustomerId} {...register("stripeCustomerId")} />
            </div>

            <h3>Personal data</h3>
            <label>
                <span>Salutation</span>
                <select {...register("person.salutation")}>
                    <option value="NONE">{mapSalutationToString("NONE")}</option>
                    <option value="MR">{mapSalutationToString("MR")}</option>
                    <option value="MRS">{mapSalutationToString("MRS")}</option>
                    <option value="DR">{mapSalutationToString("DR")}</option>
                    <option value="MS">{mapSalutationToString("MS")}</option>
                    <option value="PROF">{mapSalutationToString("PROF")}</option>
                </select>
                <FormError field="person.salutation" errors={errors}/>
            </label>

            <label>
                <span>First name</span>
                <input
                    type="text"
                    placeholder="First name"
                    {...register("person.firstName", {
                        minLength: {value: 2, message: "Too short."},
                        validate: (value, formValues) => validatePersonField(value, formValues, "First name"),
                    })}
                />
                <FormError field="person.firstName" errors={errors}/>
            </label>

            <label>
                <span>Last name</span>
                <input
                    type="text"
                    placeholder="Last name"
                    {...register("person.lastName", {
                        minLength: {value: 2, message: "Too short."},
                        validate: (value, formValues) => validatePersonField(value, formValues, "Last name"),
                    })}
                />
                <FormError field="person.lastName" errors={errors}/>
            </label>

            <label>
                <span>Full name</span>
                <input
                    type="text"
                    placeholder="Full name"
                    {...register("person.fullName", {
                        minLength: {value: 2, message: "Too short."}
                    })}
                />
                <FormError field="person.fullName" errors={errors}/>
            </label>

            <h3>Company</h3>
            <label>
                <span>Company name</span>
                <input
                    type="text"
                    placeholder="Company name"
                    {...register("companyName")}
                />
                <FormError field="companyName" errors={errors}/>
            </label>

            <h3>Address</h3>
            <label>
                <span>Address line 1</span>
                <input
                    type="text"
                    placeholder="Address line 1"
                    {...register("person.address.line1", {
                        minLength: {value: 2, message: "Too short."},
                        validate: (value, formValues) => validatePersonField(value, formValues, "Address line 1"),
                    })}
                />
                <FormError field="person.address.line1" errors={errors}/>
            </label>

            <label>
                <span>Address line 2</span>
                <input
                    type="text"
                    placeholder="Address line 2"
                    {...register("person.address.line2")}
                />
                <FormError field="person.address.line2" errors={errors}/>
            </label>

            <label>
                <span>City</span>
                <input
                    type="text"
                    placeholder="City"
                    {...register("person.address.city", {
                        minLength: {value: 2, message: "Too short."},
                        validate: (value, formValues) => validatePersonField(value, formValues, "City"),
                    })}
                />
                <FormError field="person.address.city" errors={errors}/>
            </label>

            <label>
                <span>ZIP / Postal code</span>
                <input
                    type="text"
                    placeholder="ZIP / Postal code"
                    {...register("person.address.zipOrPostalCode", {
                        minLength: {value: 2, message: "Too short."},
                        validate: (value, formValues) => validatePersonField(value, formValues, "ZIP / Postal code"),
                    })}
                />
                <FormError field="person.address.zipOrPostalCode" errors={errors}/>
            </label>

            <label>
                <span>State / Province / Region</span>
                <input
                    type="text"
                    placeholder="State / Province / Region"
                    {...register("person.address.stateOrProvinceOrRegion", {
                        minLength: {value: 2, message: "Too short."}
                    })}
                />
                <FormError field="person.address.stateOrProvinceOrRegion" errors={errors}/>
            </label>

            <label>
                <span>Country</span>
                <select {...register("person.address.country")}>
                    <option/>
                    {countries.map((country, i) => (
                        <option value={country.code} key={i}>{country.name}</option>
                    ))}
                </select>
                <FormError field="person.address.country" errors={errors}/>
            </label>

            <h3>Contact details</h3>
            <label>
                <span>Email</span>
                <input
                    type="text"
                    placeholder="Email"
                    {...register("person.contact.email", {
                        pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "Invalid email."
                        },
                        validate: (value, formValues) => validatePersonField(value, formValues, "Email"),
                    })}
                />
                <FormError field="person.contact.email" errors={errors}/>
            </label>

            <label>
                <span>Phone</span>
                <input
                    type="text"
                    placeholder="Phone"
                    {...register("person.contact.phone")}
                />
                <FormError field="person.contact.phone" errors={errors}/>
            </label>

            <label>
                <span>LinkedIn</span>
                <input
                    type="text"
                    placeholder="https://www.linkedin.com/in/jane-doe"
                    {...register("person.contact.linkedIn", {
                        pattern: {
                            value: /^(https?:\/\/)?(www\.)?linkedin\.com\/(in|pub)\/[a-zA-Z0-9_-]+(\/)?$/,
                            message: "Please enter a valid LinkedIn profile URL."
                        }
                    })}
                />
                <FormError field="person.contact.linkedIn" errors={errors}/>
            </label>

            <h3>Groups</h3>
            {dealGroups.length > 0 && (
                <label>
                    <span>Deal groups</span>
                    <Controller
                        name="dealGroups"
                        control={control}
                        render={({field: {onChange, value}}) => {
                            const options = dealGroups.map(dealGroup => ({label: dealGroup.name, value: dealGroup}))
                            const valueIds = value ? value.map(v => v.id) : []
                            return (
                                <Select
                                    options={options}
                                    value={options.filter((o) => valueIds.includes(o.value.id))}
                                    onChange={val => onChange(val.map(v => v.value))}
                                    isMulti
                                />
                            )
                        }}
                    />
                </label>
            )}

            {investorGroups.length > 0 && (
                <label>
                    <span>Investor groups</span>
                    <Controller
                        name="investorGroups"
                        control={control}
                        render={({field: {onChange, value}}) => {
                            const options = investorGroups.map(investorGroup => ({
                                label: investorGroup.name,
                                value: investorGroup
                            }))
                            const valueIds = value ? value.map(v => v.id) : []
                            return (
                                <Select
                                    options={options}
                                    value={options.filter((o) => valueIds.includes(o.value.id))}
                                    onChange={val => onChange(val.map(v => v.value))}
                                    isMulti
                                />
                            )
                        }}
                    />
                    <FormError field="investorGroups" errors={errors}/>
                </label>
            )}

            <div className={formStyles.checkbox}>
                <div className={formStyles.checkboxInner}>
                    <input
                        id="imported"
                        type="checkbox"
                        {...register("imported")}
                    />
                    <label htmlFor="imported">
                        Imported? (existing customer)
                    </label>
                </div>
                <FormError field="imported" errors={errors}/>
            </div>

            <h3>KYC</h3>
            <div className={formStyles.checkbox}>
                <div className={formStyles.checkboxInner}>
                    <input
                        id="aiStatusDeclared"
                        type="checkbox"
                        {...register("aiStatusDeclared")}
                        readOnly
                        disabled
                    />
                    <label htmlFor="aiStatusDeclared">
                        Accredited investor status declared
                    </label>
                </div>
            </div>

            <label>
                <span>Passport valid till</span>
                <Controller
                    name="kyc.passportValidTill"
                    control={control}
                    render={({field: {onChange, value}}) => {
                        const isoDate = value !== undefined ? DateTime.fromJSDate(value).toISODate() as string : undefined
                        return (
                            <input
                                type="date"
                                onChange={e => onChange(new Date(e.target.value))}
                                value={isoDate}
                                readOnly
                                disabled
                            />
                        )
                    }}
                />
            </label>

            <label>
                <span>Proof of address valid till</span>
                <Controller
                    name="kyc.proofOfAddressValidTill"
                    control={control}
                    render={({field: {value}}) => {
                        const isoDate = value !== undefined ? DateTime.fromJSDate(value).toISODate() as string : undefined
                        return (
                            <input
                                type="date"
                                value={isoDate}
                                readOnly
                                disabled
                            />
                        )
                    }}
                />
            </label>

            <div className={formStyles.checkbox}>
                <div className={formStyles.checkboxInner}>
                    <input
                        id="proofOfAiStatusAvailable"
                        type="checkbox"
                        {...register("kyc.proofOfAiStatusAvailable")}
                        readOnly
                        disabled
                    />
                    <label htmlFor="proofOfAiStatusAvailable">
                        Proof of AI status available
                    </label>
                </div>
            </div>

            <div className={formStyles.checkbox}>
                <div className={formStyles.checkboxInner}>
                    <input
                        id="started"
                        type="checkbox"
                        {...register("started")}
                        readOnly
                        disabled
                    />
                    <label htmlFor="started">
                        Started? (after KYC)
                    </label>
                </div>
            </div>

            <button
                type="submit"
                className={formStyles.submitButton}
            >
                {submitText}
            </button>
        </form>
    )
}

export default InvestorForm

function validatePersonField(
    value: string,
    formValues: InvestorType,
    fieldName: string
): boolean | string {
    return hasAnyPersonData(formValues) && isEmpty(value)
        ? `${fieldName} is required.`
        : true
}