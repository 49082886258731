import React, {FunctionComponent} from "react"
import {SubmitHandler, useForm} from "react-hook-form"
import formStyles from "../../tech/form/form.module.css"
import FormError from "../../tech/form/error.component"
import {DealCategoryType} from "./deal-category.type"

type DealCategoryFormProps = {
    category?: DealCategoryType
    onSubmit: SubmitHandler<DealCategoryType>
    submitText: string
}

const DealCategoryForm: FunctionComponent<DealCategoryFormProps> = ({ category, onSubmit, submitText }) => {
    const { register, handleSubmit, formState: { errors } } = useForm<DealCategoryType>({
        defaultValues: category
    })

    return (
        <form
            className={formStyles.form}
            onSubmit={handleSubmit(onSubmit)}
        >
            <input type="hidden" {...register("id")} />

            <label>
                <span>Name</span>
                <input
                    type="text"
                    placeholder="Name"
                    {...register("name", {
                        required: "Name is required.",
                        minLength: { value: 3, message: "Too short." }
                    })}
                />
                <FormError field="name" errors={errors} />
            </label>

            <label>
                <span>Slug</span>
                <input
                    type="text"
                    placeholder="Slug"
                    {...register("slug", {
                        required: "Slug is required.",
                        minLength: { value: 2, message: "Too short." }
                    })}
                />
                <FormError field="slug" errors={errors} />
            </label>

            <button
                type="submit"
                className={formStyles.submitButton}
            >
                {submitText}
            </button>
        </form>
    )
}

export default DealCategoryForm