import React, {useContext, useEffect, useState} from "react"
import LoadingDots from "../../../tech/loading/dots/dots.component"
import Alert from "../../../tech/alert/alert.component"
import {AlertType} from "../../../tech/alert/type.enum"
import {FetchClient} from "../../../tech/fetch/client"
import FetchContext from "../../../tech/fetch/fetch.context"
import {useNavigate} from "react-router-dom"
import {UserType} from "../../user/user.type"
import Section from "../../../tech/section/section.component"
import SectionHeading from "../../../tech/section/section-heading.component"
import InvestorForm from "../form.component"
import {SubmitHandler} from "react-hook-form"
import {InvestorType} from "../investor.type"
import {INVESTOR} from "../../../paths"
import {DealGroupType} from "../../deal-group/deal-group.type"
import {InvestorGroupType} from "../../investor-group/investor-group.type"
import {normalizeValues} from "../../../tech/form/form.util"

const InvestorNew = () => {
    const fetchClient = useContext<FetchClient>(FetchContext)
    const navigate = useNavigate()
    const [state, setState] = useState<"LOADING" | "LOADED" | "ERROR">("LOADING")
    const [usersWithoutInvestor, setUsersWithoutInvestor] = useState<UserType[]>([])
    const [dealGroups, setDealGroups] = useState<DealGroupType[]>([])
    const [investorGroups, setInvestorGroups] = useState<InvestorGroupType[]>([])

    useEffect(() => {
        const fetch = async () => {
            try {
                const [
                    fetchedDealGroups,
                    fetchedInvestorGroups,
                    fetchedUsersWithoutInvestor,
                ] = await Promise.all([
                    fetchClient.dealGroupApi.getAll(),
                    fetchClient.investorGroupApi.getAll(),
                    fetchClient.userApi.getAllWithoutInvestor(),
                ])
                setDealGroups(fetchedDealGroups)
                setInvestorGroups(fetchedInvestorGroups)
                setUsersWithoutInvestor(fetchedUsersWithoutInvestor)
                setState("LOADED")
            } catch (err) {
                console.error(err)
                setState("ERROR")
            }
        }
        fetch()
    }, [fetchClient])

    const onSubmit: SubmitHandler<InvestorType> = async (investor) => {
        setState("LOADING")
        try {
            const savedInvestor = await fetchClient.investorApi.create(normalizeValues(investor))
            navigate(INVESTOR(savedInvestor.investor.id!))
        } catch (err) {
            console.error(err)
            setState("ERROR")
        }
    }

    return (
        <>
            {state === "LOADING" && <LoadingDots/>}
            {state === "ERROR" && (
                <Alert
                    type={AlertType.ERROR}
                    text="Unknown error."
                />
            )}
            {(state === "LOADED" || state === "ERROR") && (
                <Section>
                    <SectionHeading title="Create new investor for user"/>
                    <InvestorForm
                        usersWithoutInvestor={usersWithoutInvestor}
                        dealGroups={dealGroups}
                        investorGroups={investorGroups}
                        onSubmit={onSubmit}
                        submitText="Create investor"
                    />
                </Section>
            )}
        </>
    )
}

export default InvestorNew