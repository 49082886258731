import React, {useContext, useEffect, useState} from "react"
import {useNavigate, useParams} from "react-router-dom"
import Section from "../../tech/section/section.component"
import {FetchClient} from "../../tech/fetch/client"
import FetchContext from "../../tech/fetch/fetch.context"
import LoadingDots from "../../tech/loading/dots/dots.component"
import Alert from "../../tech/alert/alert.component"
import {AlertType} from "../../tech/alert/type.enum"
import Button from "../../tech/button/button.component"
import {ButtonStyle} from "../../tech/button/style.enum"
import {SYNDICATES} from "../../paths"
import {SyndicateType} from "./syndicate.type"
import {match} from "ts-pattern"
import BackendValidationErrorsAlert from "../../tech/validation/alert.component"

const SyndicateDelete = () => {
    let { id } = useParams()
    const navigate = useNavigate()
    const fetchClient = useContext<FetchClient>(FetchContext)
    const [state, setState] = useState<"LOADING" | "LOADED" | "ERROR">("LOADING")
    const [errors, setErrors] = useState<string[]>([])
    const [syndicate, setSyndicate] = useState<SyndicateType>()

    useEffect(() => {
        const fetch = async () => {
            try {
                setSyndicate(await fetchClient.syndicateApi.get(id!))
                setState("LOADED")
            } catch (err) {
                console.error(err)
                setState("ERROR")
            }
        }
        fetch()
    }, [fetchClient, id])

    const del = async () => {
        setState("LOADING")
        match(await fetchClient.syndicateApi.delete(id!))
            .with(
                { type: "RESPONSE" },
                () => navigate(SYNDICATES)
            )
            .with(
                { type: "RESPONSE_ERROR" },
                () => setState("ERROR")
            )
            .with(
                { type: "VALIDATION_ERRORS" },
                (res) => {
                    setState("ERROR")
                    setErrors(res.errors)
                }
            )
            .exhaustive()
    }

    return (
        <>
            {state === "LOADING" && <LoadingDots/>}
            {state === "ERROR" && errors.length > 0 && <BackendValidationErrorsAlert errors={errors}/>}
            {state === "ERROR" && errors.length < 1 && (
                <Alert
                    type={AlertType.ERROR}
                    text="Unknown error."
                />
            )}
            {(state === "LOADED" || state === "ERROR") && syndicate && (
                <Section>
                    <div className="mb-5">
                        Do you really want to delete syndicate <strong>{syndicate.name}</strong> with id <strong>{syndicate.id}</strong>?
                    </div>
                    <Button
                        title="Delete permanently"
                        style={ButtonStyle.DELETE}
                        onClick={del}
                        type="button"
                    />
                </Section>
            )}
        </>
    )
}

export default SyndicateDelete