import React, {useContext, useEffect, useMemo, useState} from "react"
import LoadingDots from "../../tech/loading/dots/dots.component"
import Section from "../../tech/section/section.component"
import SectionHeading from "../../tech/section/section-heading.component"
import tableStyles from "../../tech/table/table.module.css"
import Alert from "../../tech/alert/alert.component"
import {AlertType} from "../../tech/alert/type.enum"
import {useNavigate, useSearchParams} from "react-router-dom"
import {FetchClient} from "../../tech/fetch/client"
import FetchContext from "../../tech/fetch/fetch.context"
import Button from "../../tech/button/button.component"
import {ButtonStyle} from "../../tech/button/style.enum"
import {SYNDICATE, SYNDICATE_MEMBERS_INVITATIONS, SYNDICATE_NEW} from "../../paths"
import {DateTime} from "luxon"
import {PaginatedType} from "../../tech/actions/pagination/pagination.type"
import {emptyPaginated, getPagination} from "../../tech/actions/pagination/pagination.util"
import {assembleSortOptions, getCurrentSortOption} from "./sort.util"
import {assembleFilterOptions, getCurrentFilter, mapToSyndicateFiltersOpenApi} from "./filter.util"
import Actions from "../../tech/actions/actions.component"
import {SyndicateType} from "./syndicate.type"
import ActionLink from "../../tech/link/action.component"

const SyndicateOverview = () => {
    const SYNDICATES_PER_PAGE = 50
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const fetchClient = useContext<FetchClient>(FetchContext)
    const [state, setState] = useState<"LOADING" | "LOADED" | "ERROR">("LOADING")
    const [
        syndicates,
        setSyndicates
    ] = useState<PaginatedType<SyndicateType>>(emptyPaginated)

    const sortOptions = assembleSortOptions()
    const currentSortOption = useMemo(
        () => getCurrentSortOption(sortOptions, searchParams),
        [sortOptions, searchParams]
    )

    const filters = useMemo(() => assembleFilterOptions(), [])
    const currentFilter = useMemo(
        () => getCurrentFilter(filters, searchParams),
        [filters, searchParams]
    )

    const pagination = useMemo(() => getPagination(SYNDICATES_PER_PAGE, searchParams), [searchParams])

    useEffect(() => {
        const fetch = async () => {
            try {
                setSyndicates(await fetchClient.syndicateApi.getAllPaginated(
                    currentSortOption.id,
                    mapToSyndicateFiltersOpenApi(currentFilter),
                    pagination
                ))
                setState("LOADED")
            } catch (err) {
                console.error(err)
                setState("ERROR")
            }
        }
        fetch()
    }, [fetchClient, currentFilter, currentSortOption.id, pagination])

    return (
        <Section>
            <SectionHeading
                title="Syndicates"
                subTitle={`${syndicates.total} syndicates`}
                button={<Button
                    title="New Syndicate"
                    style={ButtonStyle.SECONDARY}
                    onClick={() => navigate(SYNDICATE_NEW)}
                    type="button"
                />}
            />
            <Actions
                filter={{
                    current: currentFilter,
                    filters
                }}
                pagination={{
                    label: "syndicates",
                    elementsPerPage: SYNDICATES_PER_PAGE,
                    numberElements: syndicates.total
                }}
                sort={{
                    current: currentSortOption,
                    options: sortOptions
                }}
            >
                {state === "LOADING" && <LoadingDots/>}
                {state === "LOADED" && (
                    <div className={tableStyles.tableWrapper}>
                        <table className={tableStyles.tablePointer}>
                            <thead>
                            <tr>
                                <th>Name</th>
                                <th>Created</th>
                                <th>Modified</th>
                                <th>ID</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {syndicates.elements.map((syndicate, i) => {
                                const s = () => navigate(SYNDICATE(syndicate.id!))
                                return (
                                    <tr key={i}>
                                        <td onClick={s}>
                                            {DateTime.fromJSDate(syndicate.created!).toLocaleString(DateTime.DATETIME_MED)}
                                        </td>
                                        <td onClick={s}>
                                            {DateTime.fromJSDate(syndicate.modified!).toLocaleString(DateTime.DATETIME_MED)}
                                        </td>
                                        <td onClick={s}>
                                            {syndicate.name}
                                        </td>
                                        <td onClick={s}>
                                            {syndicate.id}
                                        </td>
                                        <td>
                                            <ActionLink
                                                text={"Members & Invitations"}
                                                link={SYNDICATE_MEMBERS_INVITATIONS(syndicate.id!)}
                                            />
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                    </div>
                )}
                {state === "ERROR" && (
                    <Alert
                        type={AlertType.ERROR}
                        text="Failed to load syndicates"
                    />
                )}
            </Actions>
        </Section>
    )
}

export default SyndicateOverview