import {UserRoleOpenApi} from "../../generated"

export type UserType = {
    id: string
    userId: string
    email?: string
    role: UserRoleOpenApi
}

export const mapUserRoleToString = (role: UserRoleOpenApi): string => {
    switch (role) {
        case "ADMINISTRATOR": return "Administrator"
        case "SYNDICATE_MANAGER": return "Syndicate Manager"
        case "USER": return "User"
    }
}