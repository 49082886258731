import React, {useContext, useEffect, useState} from "react"
import LoadingDots from "../../../../tech/loading/dots/dots.component"
import {FetchClient} from "../../../../tech/fetch/client"
import FetchContext from "../../../../tech/fetch/fetch.context"
import Alert from "../../../../tech/alert/alert.component"
import {AlertType} from "../../../../tech/alert/type.enum"
import Section from "../../../../tech/section/section.component"
import SectionHeading from "../../../../tech/section/section-heading.component"
import {KycLogType} from "../kyc-log.type"
import tableStyles from "../../../../tech/table/table.module.css"
import {useNavigate} from "react-router-dom"
import {KYC_REVIEW} from "../../../../paths"
import {DateTime} from "luxon"

const OpenKycReviewsOverview = () => {
    const navigate = useNavigate()
    const fetchClient = useContext<FetchClient>(FetchContext)
    const [state, setState] = useState<"LOADING" | "LOADED" | "ERROR">("LOADING")
    const [kycLogs, setKycLogs] = useState<KycLogType[]>([])

    useEffect(() => {
        const fetch = async () => {
            try {
                setKycLogs(await fetchClient.kycApi.getAllKycLogsToBeReviewed())
                setState("LOADED")
            } catch (err) {
                console.error(err)
                setState("ERROR")
            }
        }
        fetch()
    }, [fetchClient])

    return (
        <>
            {state === "LOADING" && <LoadingDots/>}
            {state === "LOADED" && (
                <Section>
                    <SectionHeading
                        title="Open KYC reviews"
                        subTitle={`${kycLogs.length} open KYC reviews`}
                    />
                    <div className={tableStyles.tableWrapper}>
                        <table className={tableStyles.tablePointer}>
                            <thead>
                            <tr>
                                <th>Investor</th>
                                <th>Created</th>
                                <th>Type</th>
                                <th>Num. of files</th>
                                <th>ID</th>
                            </tr>
                            </thead>
                            <tbody>
                            {kycLogs
                                .sort((a, b) => a.created.getTime() - b.created.getTime())
                                .map((kycLog, i) => (
                                    <tr
                                        key={i}
                                        onClick={() => navigate(KYC_REVIEW(kycLog.id))}
                                    >
                                        <td>{kycLog.investor.person?.firstName} {kycLog.investor.person?.lastName}</td>
                                        <td>{DateTime.fromJSDate(kycLog.created).toLocaleString(DateTime.DATETIME_MED)}</td>
                                        <td>{kycLog.type}</td>
                                        <td>{kycLog.files.length}</td>
                                        <td>{kycLog.id}</td>
                                    </tr>
                                )
                            )}
                            </tbody>
                        </table>
                    </div>
                </Section>
            )}
            {state === "ERROR" && (
                <Alert
                    type={AlertType.ERROR}
                    text="Failed to load open KYC reviews"
                />
            )}
        </>
    )
}

export default OpenKycReviewsOverview