import React, {FunctionComponent, useContext, useEffect, useState} from "react"
import LoadingDots from "../../tech/loading/dots/dots.component"
import {DealType} from "../deal/deal.type"
import {FetchClient} from "../../tech/fetch/client"
import FetchContext from "../../tech/fetch/fetch.context"
import styles from "./dashboard.module.css"
import {Link} from "react-router-dom"
import {COMPANIES} from "../../paths"

const DashboardDealsWithoutCompany: FunctionComponent = () => {
    const fetchClient = useContext<FetchClient>(FetchContext)
    const [state, setState] = useState<"LOADING" | "LOADED" | "ERROR">("LOADING")
    const [dealsWithoutValuation, setDealsWithoutValuation] = useState<DealType[]>([])

    useEffect(() => {
        const fetch = async () => {
            try {
                setDealsWithoutValuation(await fetchClient.dealApi.getAllWithoutCompany())
                setState("LOADED")
            } catch (err) {
                console.error(err)
                setState("ERROR")
            }
        }
        fetch()
    }, [fetchClient])

    return (
        <>
            {state === "LOADED" && dealsWithoutValuation.length > 0 && (
                <div className={styles.box}>
                    <div>
                        <h3>Deals without company</h3>
                        The following deals have no company assigned:
                        <ul>
                            {dealsWithoutValuation.map(d => (
                                <li key={d.id!}>
                                    <Link to={COMPANIES}>
                                        {d.name.completeOnboarding}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            )}
            {state === "LOADING" && <LoadingDots/>}
            {state === "ERROR" && <i>Error while loading.</i>}
        </>
    )
}

export default DashboardDealsWithoutCompany