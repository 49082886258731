import {SalutationOpenApi} from "../../generated"
import {AddressType} from "../address/address.type"
import {ContactType} from "../contact/contact.type"

export type PersonType = {
    salutation: SalutationOpenApi | undefined
    firstName: string
    lastName: string
    fullName?: string
    address?: AddressType
    contact: ContactType
}

export const mapSalutationToString = (salutation: SalutationOpenApi): string => {
    switch (salutation) {
        case "NONE": return "-"
        case "MR": return "Mr."
        case "MRS": return "Mrs."
        case "DR": return "Dr."
        case "MS": return "Ms."
        case "PROF": return "Prof."
    }
}