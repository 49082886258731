import React, {FunctionComponent} from "react"
import {SubmitHandler, useForm} from "react-hook-form"
import formStyles from "../../../tech/form/form.module.css"
import FormError from "../../../tech/form/error.component"
import {ManualReconciliationBankAccountWithDealsType} from "./manual-reconciliation-bank-account.type"
import {Link} from "react-router-dom"
import {DEAL} from "../../../paths"
import {CurrencyOpenApi, ManualReconciliationBankAccountTransferOriginOpenApi} from "../../../generated"
import {mapCurrencyToString} from "../../currency/currency.mapper"
import styles from "./form.module.sass"
import {SinglePurposeVehicleType} from "../../single-purpose-vehicle/single-purpose-vehicle.type"
import {isEmpty} from "../../../tech/form/form.util"

type ManualReconciliationBankAccountFormProps = {
    account?: ManualReconciliationBankAccountWithDealsType
    singlePurposeVehicles: SinglePurposeVehicleType[]
    onSubmit: SubmitHandler<ManualReconciliationBankAccountWithDealsType>
    submitText: string
}

const ManualReconciliationBankAccountForm: FunctionComponent<ManualReconciliationBankAccountFormProps> = ({
    account,
    singlePurposeVehicles,
    onSubmit,
    submitText
}) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        watch
    } = useForm<ManualReconciliationBankAccountWithDealsType>({
        defaultValues: account
    })

    return (
        <form
            className={formStyles.form}
            onSubmit={handleSubmit(onSubmit)}
        >
            <input type="hidden" {...register("id")} />

            <label>
                <span>Transfer Origin</span>
                <select
                    {...register("transferOrigin")}
                >
                    {Object.entries(ManualReconciliationBankAccountTransferOriginOpenApi).map((o, i) => (
                        <option
                            value={o[1]}
                            key={`transfer-origin-${i}`}
                        >
                            {o[1]}
                        </option>
                    ))}
                </select>
                <FormError field="transferOrigin" errors={errors}/>
            </label>

            <label>
                <span>Bank Name</span>
                <input
                    type="text"
                    placeholder="Bank Name"
                    {...register("bank.name", {
                        required: "Bank name is required.",
                        minLength: {value: 3, message: "Too short."}
                    })}
                />
                <FormError field="bank.name" errors={errors}/>
            </label>

            <label>
                <span>Bank Address</span>
                <input
                    type="text"
                    placeholder="Bank Address"
                    {...register("bank.address", {
                        required: "Bank Address is required.",
                        minLength: {value: 3, message: "Too short."}
                    })}
                />
                <FormError field="bank.address" errors={errors}/>
            </label>

            <label>
                <span>Currency</span>
                <select
                    {...register("currency", {
                        required: "Currency is required."
                    })}
                >
                    {Object.entries(CurrencyOpenApi).map((c, index) => (
                        <option
                            value={c[1]}
                            key={`currency-${index}`}
                        >
                            {mapCurrencyToString(c[1])}
                        </option>
                    ))}
                </select>
                <FormError field="currency" errors={errors}/>
            </label>

            <label>
                <span>Holder (SPV)</span>
                <select
                    {...register("holderSinglePurposeVehicle.id", {
                        required: "Holder SPV is required."
                    })}
                >
                    {singlePurposeVehicles.map((spv, index) => (
                        <option
                            value={spv.id}
                            key={`spv-${index + 1}`}
                        >
                            {spv.name} ({spv.address})
                        </option>
                    ))}
                </select>
                <FormError field="holderSinglePurposeVehicle.id" errors={errors}/>
            </label>

            <label>
                <span>Account Number</span>
                <input
                    type="text"
                    placeholder="Account number"
                    {...register("number", {
                        required: "Account number is required.",
                        minLength: {value: 3, message: "Too short."}
                    })}
                />
                <FormError field="number" errors={errors}/>
            </label>

            {watch("transferOrigin") === "OUTSIDE_US" && (
                <label>
                    <span>SWIFT</span>
                    <input
                        type="text"
                        placeholder="SWIFT"
                        {...register("swift", {
                            minLength: {value: 3, message: "Too short."},
                            validate: (value, formValues) => {
                                if (formValues.transferOrigin === "OUTSIDE_US" && isEmpty(value)) return "SWIFT is required if transfer origin is OUTSIDE_US"
                                return true
                            }
                        })}
                    />
                    <FormError field="swift" errors={errors}/>
                </label>
            )}

            {watch("transferOrigin") === "WITHIN_US" && (
                <>
                    <label>
                        <span>ACH Routing Number</span>
                        <input
                            type="text"
                            placeholder="ACH Routing Number"
                            {...register("achRoutingNumber", {
                                minLength: {value: 3, message: "Too short."},
                                validate: (value, formValues) => {
                                    if (formValues.transferOrigin === "WITHIN_US" && isEmpty(value)) return "ACH Routing Number is required if transfer origin is WITHIN_US"
                                    return true
                                }
                            })}
                        />
                        <FormError field="achRoutingNumber" errors={errors}/>
                    </label>

                    <label>
                        <span>ABA</span>
                        <input
                            type="text"
                            placeholder="ABA"
                            {...register("aba", {
                                minLength: {value: 3, message: "Too short."},
                                validate: (value, formValues) => {
                                    if (formValues.transferOrigin === "WITHIN_US" && isEmpty(value)) return "ABA is required if transfer origin is WITHIN_US"
                                    return true
                                }
                            })}
                        />
                        <FormError field="aba" errors={errors}/>
                    </label>
                </>
            )}

            {account && (
                <div>
                    <h3>Deals using this account</h3>
                    {account.deals.length > 0 ? (
                        <ul className={styles.list}>
                            {account.deals.map(d => (
                                <li>
                                    <Link to={DEAL(d.id!)}>{d.name.completeOnboarding}</Link>
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <>This account is not assigned to a deal yet.</>
                    )}
                </div>
            )}

            <button
                type="submit"
                className={formStyles.submitButton}
            >
                {submitText}
            </button>
        </form>
    )
}

export default ManualReconciliationBankAccountForm