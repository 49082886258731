import React, {FunctionComponent, useContext, useState} from "react"
import {FileType, FileUpdateType} from "../file.type"
import {SubmitHandler, useForm} from "react-hook-form"
import LoadingDots from "../../../tech/loading/dots/dots.component"
import Alert from "../../../tech/alert/alert.component"
import {AlertType} from "../../../tech/alert/type.enum"
import formStyles from "../../../tech/form/form.module.css"
import FormError from "../../../tech/form/error.component"
import {normalizeValues} from "../../../tech/form/form.util"
import {FILES} from "../../../paths"
import {useNavigate} from "react-router-dom"
import {FetchClient} from "../../../tech/fetch/client"
import FetchContext from "../../../tech/fetch/fetch.context"

type FileEditFormProps = {
    file: FileType
}

const FileEditForm: FunctionComponent<FileEditFormProps> = ({ file }) => {
    const navigate = useNavigate()
    const fetchClient = useContext<FetchClient>(FetchContext)
    const [state, setState] = useState< "NONE" | "LOADING" | "ERROR">("NONE")

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm<FileUpdateType>({
        defaultValues: { identifier: file?.identifier }
    })

    const onSubmit: SubmitHandler<FileUpdateType> = async (fileEdit) => {
        setState("LOADING")
        try {
            await fetchClient.fileApi.update(file.id, normalizeValues(fileEdit))
            navigate(FILES)
        } catch (err) {
            console.error(err)
            setState("ERROR")
        }
    }

    return (
        <>
            {state === "NONE" && (
                <form
                    className={formStyles.form}
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <label>
                        <span>Identifier</span>
                        <input
                            type="text"
                            placeholder="Identifier"
                            {...register("identifier", {
                                minLength: { value: 1, message: "Too short." }
                            })}
                        />
                        <FormError field="identifier" errors={errors} />
                    </label>

                    <button
                        type="submit"
                        className={formStyles.submitButton}
                    >
                        Save
                    </button>
                </form>
            )}
            {state === "LOADING" && <LoadingDots/>}
            {state === "ERROR" && (
                <Alert
                    type={AlertType.ERROR}
                    text="Failed to save file."
                />
            )}
        </>
    )
}

export default FileEditForm