import React, {FunctionComponent} from "react"
import FilePreview from "../../preview/preview.component"
import {FileType} from "../../file.type"
import styles from "./card.module.sass"

type FileCardProps = {
    file: FileType
}

const FileCard: FunctionComponent<FileCardProps> = ({ file }) => {
    return (
        <div className={styles.card}>
            <FilePreview
                file={file}
                maxHeight={65}
            />
            <div>
                {file.identifier ? (
                    <>
                        <div>
                            {file.identifier}
                        </div>
                        <small>{file.id}</small>
                    </>
                ) : (
                    <>
                        {file.id}
                    </>
                )}
            </div>
        </div>
    )
}

export default FileCard