import React, {FunctionComponent} from "react"
import {FieldArrayWithId, FieldErrors, UseFieldArrayRemove, UseFormRegister} from "react-hook-form"
import {DealType} from "../deal.type"
import FormError from "../../../tech/form/error.component"
import styles from "../select.module.sass"

type DealManualReconciliationBankAccountSelectSingleProps = {
    field: FieldArrayWithId<DealType, "manualReconciliationBankAccounts">
    index: number
    errors: FieldErrors<DealType>
    register: UseFormRegister<DealType>
    remove: UseFieldArrayRemove
}

const DealManualReconciliationBankAccountSelectSingle: FunctionComponent<DealManualReconciliationBankAccountSelectSingleProps> = ({
    field,
    index,
    errors,
    register,
    remove,
}) => {
    const del = (i: number) => remove(i)

    return (
        <div className={styles.box}>
            <input type="hidden" {...register(`manualReconciliationBankAccounts.${index}.manualReconciliationBankAccount`,)} />
            <div className={styles.boxTitle}>
                <div>
                    <strong>
                        {field.manualReconciliationBankAccount.number}{" "}
                        ({field.manualReconciliationBankAccount.holderSinglePurposeVehicle.name}, {field.manualReconciliationBankAccount.bank.name}, {field.manualReconciliationBankAccount.id})
                    </strong>
                </div>
            </div>
            <div>
                <label>
                    <span>Order</span>
                    <input
                        {...register(
                            `manualReconciliationBankAccounts.${index}.order`,
                            {
                                required: "Order is required."
                            }
                        )}
                        type="number"
                        min={0}
                    />
                    <FormError field={`manualReconciliationBankAccounts.${index}.order`} errors={errors}/>
                </label>
            </div>
            <div className={styles.delete}>
                <button
                    type="button"
                    onClick={() => del(index)}
                >
                    Delete
                </button>
            </div>
        </div>
    )
}

export default DealManualReconciliationBankAccountSelectSingle