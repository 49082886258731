import React, {FunctionComponent} from "react"
import {DealInvestorGroupType} from "./deal-investor-group.type"
import {Control, FieldErrors, useFieldArray, UseFormGetValues, UseFormRegister, UseFormSetValue} from "react-hook-form"
import {DealType} from "../deal.type"
import {InvestorGroupType} from "../../investor-group/investor-group.type"
import DealInvestorGroupSelectAdd from "./add.component"
import DealInvestorGroupSelectSingle from "./single.component"

type DealInvestorGroupSelectProps = {
    groups: DealInvestorGroupType[]
    groupsRaw: InvestorGroupType[]
    control: Control<DealType, any>
    errors: FieldErrors<DealType>
    register: UseFormRegister<DealType>
    getValues: UseFormGetValues<DealType>
    setValue: UseFormSetValue<DealType>
}

const DealInvestorGroupSelect: FunctionComponent<DealInvestorGroupSelectProps> = ({
    groups,
    groupsRaw,
    control,
    errors,
    register,
    getValues,
    setValue
}) => {
    const { fields, append, remove } = useFieldArray({ name: "investorGroups", control })

    if (groupsRaw.length < 1) {
        return <div>No investor groups available.</div>
    }

    return (
        <div>
            {fields.map((f, index) => (
                <DealInvestorGroupSelectSingle
                    field={f}
                    index={index}
                    errors={errors}
                    register={register}
                    remove={remove}
                    getValues={getValues}
                    setValue={setValue}
                    key={f.id}
                />
            ))}
            <DealInvestorGroupSelectAdd
                add={append}
                groups={groups}
                groupsRaw={groupsRaw}
            />
        </div>
    )
}

export default DealInvestorGroupSelect