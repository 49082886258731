import React, {FunctionComponent, useContext, useEffect, useState} from "react"
import {Link} from "react-router-dom"
import {DateTime} from "luxon"
import {FetchClient} from "../../../fetch/client"
import FetchContext from "../../../fetch/fetch.context"
import {AsynchronousActionOverviewType} from "./overview.type"
import LoadingDots from "../../../loading/dots/dots.component"
import Alert from "../../../alert/alert.component"
import {AlertType} from "../../../alert/type.enum"
import Section from "../../../section/section.component"
import SectionHeading from "../../../section/section-heading.component"
import tableStyles from "../../../table/table.module.css"
import {countErrors} from "./overview.util"
import {AsynchronousActionType} from "../action.type"
import Button from "../../../button/button.component"
import {ButtonStyle} from "../../../button/style.enum"
import LoadingCircle from "../../../loading/circle/circle.component"
import {compareDates} from "../../../sort/sort.util"
import styles from "./overview.module.sass"

const AsynchronousActionErrorOverview: FunctionComponent = () => {
    const fetchClient = useContext<FetchClient>(FetchContext)
    const [state, setState] = useState<"LOADING" | "LOADED" | "ERROR">("LOADING")
    const [actionOverview, setActionOverview] = useState<AsynchronousActionOverviewType>()
    const [actionResetting, setActionResetting] = useState<string>()
    const [actionResetResult, setActionResetResult] = useState<{
        actionId: string,
        result: "ERROR" | "SUCCESS"
    }>()

    useEffect(() => {
        const fetch = async () => {
            try {
                setActionOverview(await fetchClient.actionApi.getOverview())
                setState("LOADED")
            } catch (err) {
                console.error(err)
                setState("ERROR")
            }
        }
        fetch()
    }, [fetchClient])

    const reset = async (action: AsynchronousActionType) => {
        setActionResetting(action.id)
        try {
            setActionOverview(await fetchClient.actionApi.reset(action.id, action.actionType))
            setActionResetResult({
                actionId: action.id,
                result: "SUCCESS"
            })
        }
        catch (err) {
            fetchClient.loggingApi.sendError(
                new Date(),
                "Failed to reset action with id " + action.id,
                window.location.pathname
            )
            setActionResetResult({
                actionId: action.id,
                result: "ERROR"
            })
        }
        setActionResetting(undefined)
    }

    const errorCount = actionOverview ? countErrors(actionOverview) : 0

    return (
        <>
            {state === "LOADING" && <LoadingDots/>}
            {state === "LOADED" && actionOverview && (
                <Section>
                    <SectionHeading
                        title="Asynchronous actions with error state"
                        subTitle={`${errorCount} errors`}
                    />
                    {actionResetResult?.result === "ERROR" && (
                        <Alert
                            type={AlertType.ERROR}
                            text={`Failed to reset action with id ${actionResetResult.actionId}.`}
                        />
                    )}
                    {actionResetResult?.result === "SUCCESS" && (
                        <Alert
                            type={AlertType.SUCCESS}
                            text={`Successfully resetted action with id ${actionResetResult.actionId}.`}
                        />
                    )}
                    {errorCount > 0 ? (
                        <div className={tableStyles.tableWrapper}>
                            <table className={tableStyles.table}>
                                <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Created</th>
                                    <th>Modified</th>
                                    <th>Processed</th>
                                    <th>Message</th>
                                    <th>Details</th>
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody>
                                {Object.keys(actionOverview)
                                    .map(key => key as keyof AsynchronousActionOverviewType)
                                    .flatMap(key => actionOverview[key] as AsynchronousActionType[])
                                    .map(action => action as AsynchronousActionType)
                                    .sort((a: AsynchronousActionType, b: AsynchronousActionType) => compareDates(a.processed!, b.processed!, "DESCENDING"))
                                    .map(action => (
                                        <tr key={action.id}>
                                            <td>
                                                {action.id}<br/>
                                                <small><strong>({action.actionType})</strong></small>
                                            </td>
                                            <td>
                                                {DateTime.fromJSDate(action.created).toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS)}
                                            </td>
                                            <td>
                                                {DateTime.fromJSDate(action.modified).toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS)}
                                            </td>
                                            <td>
                                                {DateTime.fromJSDate(action.processed!).toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS)}
                                            </td>
                                            <td>
                                                {action.message}
                                            </td>
                                            <td>
                                                {action.details.map(d => (
                                                    <div key={`${action.id}-${d.title.replaceAll(" ", "_")}`}>
                                                        <strong>{d.title}:</strong> {d.content}
                                                    </div>
                                                ))}
                                                {action.getLink && action.getLink() && (
                                                    <ActionLink link={action.getLink()!}/>
                                                )}
                                            </td>
                                            <td>
                                                {actionResetting === action.id ? (
                                                    <LoadingCircle
                                                        width={12}
                                                        height={12}
                                                    />
                                                ) : (
                                                    <Button
                                                        title="Reset"
                                                        style={ButtonStyle.SECONDARY}
                                                        small={true}
                                                        type="button"
                                                        onClick={() => reset(action)}
                                                        disabled={actionResetting !== undefined}
                                                    />
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    ) : (
                        <>
                            No errors.
                        </>
                    )}
                </Section>
                )}
            {state === "ERROR" && (
                <Alert
                    type={AlertType.ERROR}
                    text="Failed to load errorneous asynchronous actions."
                />
            )}
        </>
    )
}

export default AsynchronousActionErrorOverview

const ActionLink: FunctionComponent<{ link: { title: string, link: string } }> = ({ link }) => (
    <Link
        to={link.link}
        className={styles.link}
    >
        &rarr; {link.title}
    </Link>
)