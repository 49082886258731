import React, {createRef, FunctionComponent} from "react"
import {ManualReconciliationBankAccountType} from "../../reconciliation/account/manual-reconciliation-bank-account.type"
import {DealManualReconciliationBankAccountType} from "./deal-manual-reconciliation-bank-account.type"
import styles from "../select.module.sass"

type DealManualReconciliationBankAccountSelectAddProps = {
    add: (account: DealManualReconciliationBankAccountType) => void
    manualReconciliationBankAccounts: DealManualReconciliationBankAccountType[]
    manualReconciliationBankAccountsRaw: ManualReconciliationBankAccountType[]
}

const DealManualReconciliationBankAccountSelectAdd: FunctionComponent<DealManualReconciliationBankAccountSelectAddProps> = ({
    add,
    manualReconciliationBankAccounts,
    manualReconciliationBankAccountsRaw
}) => {
    const selectRef = createRef<HTMLSelectElement>()

    const notAlreadySelectedAccounts = manualReconciliationBankAccountsRaw
        .filter(account => !manualReconciliationBankAccounts.map(dealAccount => dealAccount.manualReconciliationBankAccount.id).includes(account.id))

    const onAdd = () => {
        const foundAccount = manualReconciliationBankAccountsRaw.filter(g => g.id === selectRef.current!.value)[0]
        add({
            manualReconciliationBankAccount: foundAccount,
            order: 0
        })
    }

    return (
        <div className={styles.add}>
            {notAlreadySelectedAccounts.length > 0 ? (
                <>
                    <select ref={selectRef}>
                        {notAlreadySelectedAccounts.map(account => (
                            <option
                                key={`add-option-${account.id}`}
                                value={account.id}
                            >
                                {account.number} ({account.holderSinglePurposeVehicle.name}, {account.bank.name}, {account.id})
                            </option>
                        ))}
                    </select>
                    <button
                        type="button"
                        onClick={onAdd}
                    >
                        Add
                    </button>
                </>
            ) : (
                <>No more accounts available.</>
            )}
        </div>
    )
}

export default DealManualReconciliationBankAccountSelectAdd