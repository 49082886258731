import {CollectionDeviationStateOpenApi, CollectionDeviationTypeOpenApi} from "../../../generated"
import {InvestmentType} from "../../investment/investment.type"
import {CollectionType} from "../collection.type"
import {ManualReconciliationLogType} from "../../reconciliation/log/manual-reconciliation-log.type"

export type CollectionDeviationType = {
    id: string
    created: Date
    modified: Date
    state: CollectionDeviationStateOpenApi
    type: CollectionDeviationTypeOpenApi
    collection?: CollectionType
    manualReconciliationLog?: ManualReconciliationLogType
    investment: InvestmentType
}

export function mapCollectionDeviationTypeToString(type: CollectionDeviationTypeOpenApi): string {
    switch (type) {
        case "PAYMENT_RECEIVED_72_HOURS_AFTER_EXCHANGE_RATE_DATE":
            return "Payment received 72 hours after exchange rate date"
        case "AMOUNT_DEVIATES":
        default:
            return "Amount deviates"
    }
}