import {CollectionAmountOpenApi, CollectionOpenApi, CollectionPaymentOpenApi, CollectionReceiverOpenApi, CollectionSenderOpenApi} from "../../generated"
import {CollectionAmountType, CollectionPaymentType, CollectionReceiverType, CollectionSenderType, CollectionType} from "./collection.type"
import {mapOpenApiToAccountNumber} from "./account/collection-account.mapper"

export function mapOpenApiToCollection(collection: CollectionOpenApi): CollectionType {
    return new CollectionType({
        id: collection.id,
        wallexId: collection.wallexId,
        created: collection.created,
        modified: collection.modified,
        amount: mapOpenApiToCollectionAmount(collection.amount),
        customerReference: collection.customerReference,
        creditedAt: collection.creditedAt,
        currency: collection.currency,
        fee: collection.fee,
        payment: mapOpenApiToCollectionPayment(collection.payment),
        receiver: mapOpenApiToCollectionReceiver(collection.receiver),
        sender: mapOpenApiToCollectionSender(collection.sender),
        status: collection.status
    })
}

export function mapOpenApiToCollectionAmount(amount: CollectionAmountOpenApi): CollectionAmountType {
    return {
        actual: amount.actual,
        origin: amount.origin,
    }
}

export function mapOpenApiToCollectionPayment(payment: CollectionPaymentOpenApi): CollectionPaymentType {
    return {
        type: payment.type,
        details: payment.details,
    }
}

export function mapOpenApiToCollectionReceiver(receiver: CollectionReceiverOpenApi): CollectionReceiverType {
    return {
        accountHolderName: receiver.accountHolderName,
        accountNumber: mapOpenApiToAccountNumber(receiver.accountNumber),
        bankName: receiver.bankName,
        paymentType: receiver.paymentType,
    }
}

export function mapOpenApiToCollectionSender(sender: CollectionSenderOpenApi): CollectionSenderType {
    return {
        name: sender.name,
        address: sender.address,
        country: sender.country,
        routingCode: sender.routingCode,
    }
}