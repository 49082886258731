import {WaitlistEntryOpenApi} from "../../generated"
import {WaitlistEntryType} from "./waitlist.type"
import {mapContactToOpenApi, mapOpenApiToContact} from "../contact/contact.mapper"

export function mapOpenApiToWaitlistEntry(waitlistEntry: WaitlistEntryOpenApi): WaitlistEntryType {
    return {
        id: waitlistEntry.id,
        created: waitlistEntry.created,
        confirmed: waitlistEntry.confirmed,
        firstName: waitlistEntry.firstName,
        lastName: waitlistEntry.lastName,
        contact: mapOpenApiToContact(waitlistEntry.contact),
        aiStatusDeclared: waitlistEntry.aiStatusDeclared,
        referralCode: waitlistEntry.referralCode
    }
}

export function mapWaitlistEntryToOpenApi(waitlistEntry: WaitlistEntryType): WaitlistEntryOpenApi {
    return {
        id: waitlistEntry.id,
        created: waitlistEntry.created,
        confirmed: waitlistEntry.confirmed,
        firstName: waitlistEntry.firstName,
        lastName: waitlistEntry.lastName,
        contact: mapContactToOpenApi(waitlistEntry.contact),
        aiStatusDeclared: waitlistEntry.aiStatusDeclared,
        referralCode: waitlistEntry.referralCode
    }
}