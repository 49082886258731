/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CollectionAmountOpenApi } from './CollectionAmountOpenApi';
import {
    CollectionAmountOpenApiFromJSON,
    CollectionAmountOpenApiFromJSONTyped,
    CollectionAmountOpenApiToJSON,
} from './CollectionAmountOpenApi';
import type { CollectionPaymentOpenApi } from './CollectionPaymentOpenApi';
import {
    CollectionPaymentOpenApiFromJSON,
    CollectionPaymentOpenApiFromJSONTyped,
    CollectionPaymentOpenApiToJSON,
} from './CollectionPaymentOpenApi';
import type { CollectionReceiverOpenApi } from './CollectionReceiverOpenApi';
import {
    CollectionReceiverOpenApiFromJSON,
    CollectionReceiverOpenApiFromJSONTyped,
    CollectionReceiverOpenApiToJSON,
} from './CollectionReceiverOpenApi';
import type { CollectionSenderOpenApi } from './CollectionSenderOpenApi';
import {
    CollectionSenderOpenApiFromJSON,
    CollectionSenderOpenApiFromJSONTyped,
    CollectionSenderOpenApiToJSON,
} from './CollectionSenderOpenApi';
import type { CollectionStatusOpenApi } from './CollectionStatusOpenApi';
import {
    CollectionStatusOpenApiFromJSON,
    CollectionStatusOpenApiFromJSONTyped,
    CollectionStatusOpenApiToJSON,
} from './CollectionStatusOpenApi';
import type { CurrencyOpenApi } from './CurrencyOpenApi';
import {
    CurrencyOpenApiFromJSON,
    CurrencyOpenApiFromJSONTyped,
    CurrencyOpenApiToJSON,
} from './CurrencyOpenApi';

/**
 * 
 * @export
 * @interface CollectionOpenApi
 */
export interface CollectionOpenApi {
    /**
     * 
     * @type {string}
     * @memberof CollectionOpenApi
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CollectionOpenApi
     */
    wallexId: string;
    /**
     * 
     * @type {Date}
     * @memberof CollectionOpenApi
     */
    created: Date;
    /**
     * 
     * @type {Date}
     * @memberof CollectionOpenApi
     */
    modified: Date;
    /**
     * 
     * @type {CollectionAmountOpenApi}
     * @memberof CollectionOpenApi
     */
    amount: CollectionAmountOpenApi;
    /**
     * 
     * @type {string}
     * @memberof CollectionOpenApi
     */
    customerReference?: string;
    /**
     * 
     * @type {Date}
     * @memberof CollectionOpenApi
     */
    creditedAt: Date;
    /**
     * 
     * @type {CurrencyOpenApi}
     * @memberof CollectionOpenApi
     */
    currency: CurrencyOpenApi;
    /**
     * 
     * @type {number}
     * @memberof CollectionOpenApi
     */
    fee: number;
    /**
     * 
     * @type {CollectionPaymentOpenApi}
     * @memberof CollectionOpenApi
     */
    payment: CollectionPaymentOpenApi;
    /**
     * 
     * @type {CollectionReceiverOpenApi}
     * @memberof CollectionOpenApi
     */
    receiver: CollectionReceiverOpenApi;
    /**
     * 
     * @type {CollectionSenderOpenApi}
     * @memberof CollectionOpenApi
     */
    sender: CollectionSenderOpenApi;
    /**
     * 
     * @type {CollectionStatusOpenApi}
     * @memberof CollectionOpenApi
     */
    status: CollectionStatusOpenApi;
}

/**
 * Check if a given object implements the CollectionOpenApi interface.
 */
export function instanceOfCollectionOpenApi(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "wallexId" in value;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "modified" in value;
    isInstance = isInstance && "amount" in value;
    isInstance = isInstance && "creditedAt" in value;
    isInstance = isInstance && "currency" in value;
    isInstance = isInstance && "fee" in value;
    isInstance = isInstance && "payment" in value;
    isInstance = isInstance && "receiver" in value;
    isInstance = isInstance && "sender" in value;
    isInstance = isInstance && "status" in value;

    return isInstance;
}

export function CollectionOpenApiFromJSON(json: any): CollectionOpenApi {
    return CollectionOpenApiFromJSONTyped(json, false);
}

export function CollectionOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): CollectionOpenApi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'wallexId': json['wallexId'],
        'created': (new Date(json['created'])),
        'modified': (new Date(json['modified'])),
        'amount': CollectionAmountOpenApiFromJSON(json['amount']),
        'customerReference': !exists(json, 'customerReference') ? undefined : json['customerReference'],
        'creditedAt': (new Date(json['creditedAt'])),
        'currency': CurrencyOpenApiFromJSON(json['currency']),
        'fee': json['fee'],
        'payment': CollectionPaymentOpenApiFromJSON(json['payment']),
        'receiver': CollectionReceiverOpenApiFromJSON(json['receiver']),
        'sender': CollectionSenderOpenApiFromJSON(json['sender']),
        'status': CollectionStatusOpenApiFromJSON(json['status']),
    };
}

export function CollectionOpenApiToJSON(value?: CollectionOpenApi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'wallexId': value.wallexId,
        'created': (value.created.toISOString()),
        'modified': (value.modified.toISOString()),
        'amount': CollectionAmountOpenApiToJSON(value.amount),
        'customerReference': value.customerReference,
        'creditedAt': (value.creditedAt.toISOString()),
        'currency': CurrencyOpenApiToJSON(value.currency),
        'fee': value.fee,
        'payment': CollectionPaymentOpenApiToJSON(value.payment),
        'receiver': CollectionReceiverOpenApiToJSON(value.receiver),
        'sender': CollectionSenderOpenApiToJSON(value.sender),
        'status': CollectionStatusOpenApiToJSON(value.status),
    };
}

