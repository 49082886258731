/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ImageOpenApi } from './ImageOpenApi';
import {
    ImageOpenApiFromJSON,
    ImageOpenApiFromJSONTyped,
    ImageOpenApiToJSON,
} from './ImageOpenApi';
import type { InvestorGroupOpenApi } from './InvestorGroupOpenApi';
import {
    InvestorGroupOpenApiFromJSON,
    InvestorGroupOpenApiFromJSONTyped,
    InvestorGroupOpenApiToJSON,
} from './InvestorGroupOpenApi';
import type { UserOpenApi } from './UserOpenApi';
import {
    UserOpenApiFromJSON,
    UserOpenApiFromJSONTyped,
    UserOpenApiToJSON,
} from './UserOpenApi';

/**
 * 
 * @export
 * @interface SyndicateOpenApi
 */
export interface SyndicateOpenApi {
    /**
     * 
     * @type {string}
     * @memberof SyndicateOpenApi
     */
    id?: string;
    /**
     * 
     * @type {Date}
     * @memberof SyndicateOpenApi
     */
    created?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SyndicateOpenApi
     */
    modified?: Date;
    /**
     * 
     * @type {string}
     * @memberof SyndicateOpenApi
     */
    name: string;
    /**
     * 
     * @type {ImageOpenApi}
     * @memberof SyndicateOpenApi
     */
    logo: ImageOpenApi;
    /**
     * 
     * @type {Array<InvestorGroupOpenApi>}
     * @memberof SyndicateOpenApi
     */
    investorGroups: Array<InvestorGroupOpenApi>;
    /**
     * 
     * @type {Array<UserOpenApi>}
     * @memberof SyndicateOpenApi
     */
    managers: Array<UserOpenApi>;
}

/**
 * Check if a given object implements the SyndicateOpenApi interface.
 */
export function instanceOfSyndicateOpenApi(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "logo" in value;
    isInstance = isInstance && "investorGroups" in value;
    isInstance = isInstance && "managers" in value;

    return isInstance;
}

export function SyndicateOpenApiFromJSON(json: any): SyndicateOpenApi {
    return SyndicateOpenApiFromJSONTyped(json, false);
}

export function SyndicateOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): SyndicateOpenApi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'modified': !exists(json, 'modified') ? undefined : (new Date(json['modified'])),
        'name': json['name'],
        'logo': ImageOpenApiFromJSON(json['logo']),
        'investorGroups': ((json['investorGroups'] as Array<any>).map(InvestorGroupOpenApiFromJSON)),
        'managers': ((json['managers'] as Array<any>).map(UserOpenApiFromJSON)),
    };
}

export function SyndicateOpenApiToJSON(value?: SyndicateOpenApi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'created': value.created === undefined ? undefined : (value.created.toISOString()),
        'modified': value.modified === undefined ? undefined : (value.modified.toISOString()),
        'name': value.name,
        'logo': ImageOpenApiToJSON(value.logo),
        'investorGroups': ((value.investorGroups as Array<any>).map(InvestorGroupOpenApiToJSON)),
        'managers': ((value.managers as Array<any>).map(UserOpenApiToJSON)),
    };
}

