import React, {useContext, useEffect, useState} from "react"
import {useParams} from "react-router-dom"
import {CollectionDeviationType, mapCollectionDeviationTypeToString} from "./collection-deviation.type"
import {FetchClient} from "../../../tech/fetch/client"
import FetchContext from "../../../tech/fetch/fetch.context"
import SectionHeading from "../../../tech/section/section-heading.component"
import Section from "../../../tech/section/section.component"
import LoadingDots from "../../../tech/loading/dots/dots.component"
import {AlertType} from "../../../tech/alert/type.enum"
import Alert from "../../../tech/alert/alert.component"
import InvestmentTable from "../../investment/table.component"
import tableStyles from "../../../tech/table/table.module.css"
import {DateTime} from "luxon"
import TableAction from "../../../tech/link/action.component"
import {INVESTMENT} from "../../../paths"
import CollectionsTable from "../table.component"
import Button from "../../../tech/button/button.component"
import {CollectionDeviationStateOpenApi} from "../../../generated"
import {ButtonStyle} from "../../../tech/button/style.enum"
import ManualReconciliationLogTable from "../../reconciliation/log/table.component"

const CollectionDeviationSingle = () => {
    let { id } = useParams()
    const fetchClient = useContext<FetchClient>(FetchContext)
    const [state, setState] = useState<"LOADING" | "LOADED" | "ERROR">("LOADING")
    const [collectionDeviation, setCollectionDeviation] = useState<CollectionDeviationType>()

    useEffect(() => {
        const fetch = async () => {
            try {
                setCollectionDeviation((await fetchClient.collectionApi.getCollectionDeviation(id!)))
                setState("LOADED")
            } catch (err) {
                console.error(err)
                setState("ERROR")
            }
        }
        fetch()
    }, [fetchClient, id])

    const toggleState = async () => {
        try {
            setState("LOADING")
            const newState = collectionDeviation!.state === CollectionDeviationStateOpenApi.Open
                ? CollectionDeviationStateOpenApi.Completed
                : CollectionDeviationStateOpenApi.Open
            setCollectionDeviation(await fetchClient.collectionApi.updateCollectionDeviationState(
                collectionDeviation!.id!,
                newState
            ))
            setState("LOADED")
        }
        catch (err) {
            console.error(err)
            setState("ERROR")
        }
    }

    return (
        <>
            {state === "LOADING" && <LoadingDots/>}
            {state === "LOADED" && collectionDeviation && (
                <Section>
                    <>
                        <SectionHeading
                            title="Collection deviation"
                            button={<Button
                                title={collectionDeviation.state === CollectionDeviationStateOpenApi.Completed
                                    ? "Re-open"
                                    : "Complete"}
                                style={ButtonStyle.SECONDARY}
                                onClick={toggleState}
                                type="button"
                            />}
                        />
                        <div className={tableStyles.tableWrapper}>
                            <table className={tableStyles.table}>
                                <tbody>
                                <tr>
                                    <td><strong>Id</strong></td>
                                    <td>{collectionDeviation.id}</td>
                                </tr>
                                <tr>
                                    <td><strong>Type</strong></td>
                                    <td>{mapCollectionDeviationTypeToString(collectionDeviation.type)}</td>
                                </tr>
                                <tr>
                                    <td><strong>Created</strong></td>
                                    <td>{DateTime.fromJSDate(collectionDeviation.created).toLocaleString(DateTime.DATETIME_MED)}</td>
                                </tr>
                                <tr>
                                    <td><strong>Modified</strong></td>
                                    <td>{DateTime.fromJSDate(collectionDeviation.modified).toLocaleString(DateTime.DATETIME_MED)}</td>
                                </tr>
                                <tr>
                                    <td><strong>Investment</strong></td>
                                    <td>
                                        <TableAction
                                            text={"Go to investment"}
                                            link={INVESTMENT(collectionDeviation.investment.id)}
                                        />
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>

                        <h3 className={tableStyles.separatingHeadline}>Collection / Manual reconciliation log</h3>
                        {collectionDeviation.collection && (
                            <CollectionsTable collections={[collectionDeviation.collection]}/>
                        )}
                        {collectionDeviation.manualReconciliationLog && (
                            <ManualReconciliationLogTable
                                logs={[collectionDeviation.manualReconciliationLog]}
                                showInvestment={false}
                            />
                        )}

                        <h3 className={tableStyles.separatingHeadline}>Investment</h3>
                        <InvestmentTable investment={collectionDeviation.investment}/>
                    </>
                </Section>
            )}
            {state === "ERROR" && (
                <Alert
                    type={AlertType.ERROR}
                    text="Unexpected error."
                />
            )}
        </>
    )
}

export default CollectionDeviationSingle