import React, {FunctionComponent} from "react"
import {SubmitHandler, useForm} from "react-hook-form"
import formStyles from "../../tech/form/form.module.css"
import FormError from "../../tech/form/error.component"
import {InvestorGroupType} from "./investor-group.type"

type InvestorGroupFormProps = {
    investorGroup?: InvestorGroupType
    onSubmit: SubmitHandler<InvestorGroupType>
    submitText: string
}

const InvestorGroupForm: FunctionComponent<InvestorGroupFormProps> = ({ investorGroup, onSubmit, submitText }) => {
    const { register, handleSubmit, formState: { errors } } = useForm<InvestorGroupType>({
        defaultValues: investorGroup
    })

    return (
        <form
            className={formStyles.form}
            onSubmit={handleSubmit(onSubmit)}
        >
            <input type="hidden" {...register("id")} />

            <label>
                <span>Name</span>
                <input
                    type="text"
                    placeholder="Name"
                    {...register("name", {
                        required: "Name is required.",
                        minLength: { value: 3, message: "Too short." }
                    })}
                />
                <FormError field="name" errors={errors} />
            </label>

            <label>
                <span>Description</span>
                <textarea
                    {...register("description", {
                        maxLength: { value: 500, message: "Too long." }
                    })}
                ></textarea>
                <FormError field="description" errors={errors} />
            </label>

            <button
                type="submit"
                className={formStyles.submitButton}
            >
                {submitText}
            </button>
        </form>
    )
}

export default InvestorGroupForm