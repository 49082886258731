import {CurrencyOpenApi} from "../../generated"
import {round} from "./round.util"
import {DateTime} from "luxon"

export function formatAmountWithCurrency(
    value: number,
    currency: CurrencyOpenApi
): string {
    const rounded = round(value, 2)
    return new Intl.NumberFormat("en", {
        currency: currency,
        style: "currency",
        maximumFractionDigits: 2
    }).format(rounded)
}

export function formatDate(date: Date | undefined) {
    return date
        ? DateTime.fromJSDate(date).toLocaleString(DateTime.DATE_SHORT)
        : "none"
}