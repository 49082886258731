import {DealDescriptionType, DealEventType, DealLogosType, DealNameType, DealSlideDeckType, DealType} from "./deal.type"
import {DealEventOpenApi, DealLogosOpenApi, DealOpenApi, DealSlideDeckOpenApi} from "../../generated"
import {mapDealGroupToOpenApi, mapOpenApiToDealGroup} from "../deal-group/deal-group.mapper"
import {mapDealCategoryToOpenApi, mapOpenApiToDealCategory} from "../deal-category/deal-category.mapper"
import {mapDealGeographyToOpenApi, mapOpenApiToDealGeography} from "../deal-geography/deal-geography.mapper"
import {normalizeStringToStringArray} from "./deal.util"
import {mapDealTileToOpenApi, mapOpenApiToDealTile} from "../deal-tile/deal-tile.mapper"
import {mapDealFeeStructureToOpenApi, mapOpenApiToDealFeeStructure} from "./fee/deal-fee-structure.mapper"
import {mapDealInvestorGroupToOpenApi, mapOpenApiToDealInvestorGroup} from "./investorgroup/deal-investor-group.mapper"
import {
    mapOpenApiToSinglePurposeVehicle,
    mapSinglePurposeVehicleToOpenApi
} from "../single-purpose-vehicle/single-purpose-vehicle.mapper"
import {mapCompanyEmbeddableToOpenApi, mapOpenApiToCompanyEmbeddable} from "../company/company.mapper"
import {mapImageToOpenApi, mapOpenApiToImage} from "../../tech/image/image.mapper"
import {
    mapDealManualReconciliationBankAccountToOpenApi,
    mapOpenApiToDealManualReconciliationBankAccount
} from "./reconciliationaccount/deal-manual-reconciliation-bank-account.mapper"

export function mapOpenApiToDeal(deal: DealOpenApi): DealType {
    return new DealType({
        id: deal.id,
        name: mapOpenApiToDealName(deal),
        description: mapOpenApiToDealDescription(deal),
        company: deal.company && mapOpenApiToCompanyEmbeddable(deal.company),
        companyId: deal.companyId,
        categories: deal.categories.map(c => mapOpenApiToDealCategory(c)),
        geography: deal.geography && mapOpenApiToDealGeography(deal.geography),
        logos: deal.logos && mapOpenApiToDealLogos(deal.logos),
        image: deal.image && mapOpenApiToImage(deal.image),
        stage: deal.stage,
        outcome: deal.outcome,
        waitingState: deal.waitingState,
        industry: deal.industry,
        minTarget: deal.minTarget,
        minTicket: deal.minTicket,
        maxTarget: deal.maxTarget,
        finalDeadline: deal.finalDeadline,
        currency: deal.currency,
        investedAmount: deal.investedAmount,
        exclusive: deal.exclusive,
        dealGroups: deal.dealGroups ? deal.dealGroups.map(g => mapOpenApiToDealGroup(g)) : [],
        investorGroups: deal.investorGroups ? deal.investorGroups.map(g => mapOpenApiToDealInvestorGroup(g)) : [],
        documentTemplateIds: deal.documentTemplateIds,
        investors: deal.investors,
        termsTags: deal.termsTags,
        tiles: deal.tiles.map(t => mapOpenApiToDealTile(t)),
        slideDeck: deal.slideDeck && mapOpenApiToDealSlideDeck(deal.slideDeck),
        event: deal.event && mapOpenApiToDealEvent(deal.event),
        feeStructure: mapOpenApiToDealFeeStructure(deal.feeStructure),
        manualReconciliationBankAccounts: deal.manualReconciliationBankAccounts.map(mapOpenApiToDealManualReconciliationBankAccount),
        singlePurposeVehicle: mapOpenApiToSinglePurposeVehicle(deal.singlePurposeVehicle),
        paymentProcessingType: deal.paymentProcessingType,
        paymentInstructionsTrigger: deal.paymentInstructionsTrigger,
        paymentInstructionsManuallyTriggered: deal.paymentInstructionsManuallyTriggered,
        published: deal.published,
        exited: deal.exited,
        exitType: deal.exitType,
        imported: deal.imported,
        publiclyAvailable: deal.publiclyAvailable,
        publiclyAvailableDataPoints: deal.publiclyAvailableDataPoints,
        showCompletelyWithoutOnboarding: deal.showCompletelyWithoutOnboarding
    })
}

export function mapDealToOpenApi(deal: DealType): DealOpenApi {
    return {
        id: deal.id,
        nameCompleteOnboarding: deal.name.completeOnboarding,
        nameIncompleteOnboarding: deal.name.incompleteOnboarding,
        descriptionCompleteOnboarding: deal.description.completeOnboarding,
        descriptionIncompleteOnboarding: deal.description.incompleteOnboarding,
        company: deal.company && mapCompanyEmbeddableToOpenApi(deal.company),
        companyId: deal.companyId,
        categories: deal.categories.map(c => mapDealCategoryToOpenApi(c)),
        geography: deal.geography && mapDealGeographyToOpenApi(deal.geography),
        logos: deal.logos && mapDealLogosToOpenApi(deal.logos),
        image: deal.image && mapImageToOpenApi(deal.image),
        stage: deal.stage,
        outcome: deal.outcome,
        industry: deal.industry,
        minTarget: deal.minTarget,
        minTicket: deal.minTicket,
        maxTarget: deal.maxTarget,
        currency: deal.currency,
        finalDeadline: deal.finalDeadline,
        investedAmount: deal.investedAmount,
        exclusive: deal.exclusive,
        dealGroups: deal.dealGroups ? deal.dealGroups.map(g => mapDealGroupToOpenApi(g)) : [],
        investorGroups: deal.investorGroups ? deal.investorGroups.map(g => mapDealInvestorGroupToOpenApi(g)) : [],
        documentTemplateIds: deal.documentTemplateIds,
        investors: normalizeStringToStringArray(deal.investors),
        termsTags: normalizeStringToStringArray(deal.termsTags),
        tiles: deal.tiles.map(t => mapDealTileToOpenApi(t)),
        slideDeck: deal.slideDeck && mapDealSlideDeckToOpenApi(deal.slideDeck),
        event: deal.event && mapDealEventToOpenApi(deal.event),
        feeStructure: mapDealFeeStructureToOpenApi(deal.feeStructure),
        manualReconciliationBankAccounts: deal.manualReconciliationBankAccounts.map(mapDealManualReconciliationBankAccountToOpenApi),
        singlePurposeVehicle: mapSinglePurposeVehicleToOpenApi(deal.singlePurposeVehicle),
        paymentProcessingType: deal.paymentProcessingType,
        paymentInstructionsTrigger: deal.paymentInstructionsTrigger,
        paymentInstructionsManuallyTriggered: deal.paymentInstructionsManuallyTriggered,
        exited: deal.exited,
        exitType: deal.exitType,
        published: deal.published,
        imported: deal.imported,
        publiclyAvailable: deal.publiclyAvailable,
        publiclyAvailableDataPoints: deal.publiclyAvailableDataPoints,
        showCompletelyWithoutOnboarding: deal.showCompletelyWithoutOnboarding,
        favourite: false,
        objectType: "Deal"
    }
}

export function mapOpenApiToDealName(deal: DealOpenApi): DealNameType {
    return {
        completeOnboarding: deal.nameCompleteOnboarding,
        incompleteOnboarding: deal.nameIncompleteOnboarding,
    }
}

export function mapOpenApiToDealDescription(deal: DealOpenApi): DealDescriptionType {
    return {
        completeOnboarding: deal.descriptionCompleteOnboarding,
        incompleteOnboarding: deal.descriptionIncompleteOnboarding,
    }
}

export function mapOpenApiToDealEvent(event: DealEventOpenApi): DealEventType {
    return {
        title: event.title,
        start: event.start,
        end: event.end,
        location: event.location,
        url: event.url
    }
}

export function mapDealEventToOpenApi(event: DealEventType): DealEventOpenApi {
    return {
        title: event.title,
        start: event.start,
        end: event.end,
        location: event.location,
        url: event.url
    }
}

export function mapOpenApiToDealLogos(logos: DealLogosOpenApi): DealLogosType {
    return {
        normal: logos.normal && mapOpenApiToImage(logos.normal),
        light: logos.light && mapOpenApiToImage(logos.light)
    }
}

export function mapDealLogosToOpenApi(logos: DealLogosType): DealLogosOpenApi {
    return {
        normal: logos.normal && mapImageToOpenApi(logos.normal),
        light: logos.light && mapImageToOpenApi(logos.light)
    }
}

export function mapOpenApiToDealSlideDeck(slideDeck: DealSlideDeckOpenApi): DealSlideDeckType {
    return {
        fileId: slideDeck.fileId,
        fileIdentifier: slideDeck.fileIdentifier,
        url: slideDeck.url
    }
}

export function mapDealSlideDeckToOpenApi(slideDeck: DealSlideDeckType): DealSlideDeckOpenApi {
    return {
        fileId: slideDeck.fileId,
        fileIdentifier: slideDeck.fileIdentifier,
        url: slideDeck.url
    }
}