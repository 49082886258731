import {ActionsSortOptionType} from "../../tech/actions/sort/sort.type"
import {getSort} from "../../tech/actions/sort/sort.util"
import {WaitlistSortOpenApi} from "../../generated"

export const DEFAULT_SORT = {
    id: WaitlistSortOpenApi.CreatedDescending,
    label: "Created (DESC)"
}

export function assembleSortOptions(): ActionsSortOptionType<WaitlistSortOpenApi>[] {
    return [
        {
            id: WaitlistSortOpenApi.CreatedAscending,
            label: "Created (ASC)"
        },
        DEFAULT_SORT,
        {
            id: WaitlistSortOpenApi.FirstNameAscending,
            label: "First Name (ASC)"
        },
        {
            id: WaitlistSortOpenApi.FirstNameDescending,
            label: "First Name (DESC)"
        },
        {
            id: WaitlistSortOpenApi.LastNameAscending,
            label: "Last Name (ASC)"
        },
        {
            id: WaitlistSortOpenApi.LastNameDescending,
            label: "Last Name (DESC)"
        },
    ]
}

export function getCurrentSortOption(
    options: ActionsSortOptionType<WaitlistSortOpenApi>[],
    searchParams: URLSearchParams
): ActionsSortOptionType<WaitlistSortOpenApi> {
    return (options.find(o => o.id === getSort(searchParams)) || DEFAULT_SORT)
}