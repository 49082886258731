import {WaitlistFiltersOpenApi} from "../../generated"
import {getFilter, getFilterValue} from "../../tech/actions/filter/filter.util"
import {ActionsFilterType, ActionsFilterTypeAndValueType} from "../../tech/actions/filter/filter.type"

export type WaitlistFilterType = "ID"
    | "AI_STATUS_DECLARED"
    | "CONFIRMED"
    | "EMAIL"
    | "FIRST_NAME"
    | "LAST_NAME"
    | "REFERRAL_CODE"

export function assembleFilterOptions(): ActionsFilterType<WaitlistFilterType>[] {
    return [
        {
            type: "ID",
            label: "ID",
            value: "STRING"
        },
        {
            type: "AI_STATUS_DECLARED",
            label: "AI Status Declared",
            value: "BOOLEAN"
        },
        {
            type: "CONFIRMED",
            label: "Confirmed",
            value: "BOOLEAN"
        },
        {
            type: "EMAIL",
            label: "Email",
            value: "STRING"
        },
        {
            type: "FIRST_NAME",
            label: "First Name",
            value: "STRING"
        },
        {
            type: "LAST_NAME",
            label: "Last Name",
            value: "STRING"
        },
        {
            type: "REFERRAL_CODE",
            label: "Referral Code",
            value: "STRING"
        },
    ]
}

export function getCurrentFilter(
    filters: ActionsFilterType<WaitlistFilterType>[],
    searchParams: URLSearchParams
): ActionsFilterTypeAndValueType<WaitlistFilterType> | undefined {
    const foundFilter = filters.find(f => f.type === getFilter(searchParams))
    const foundFilterValue = getFilterValue(searchParams)
    if (foundFilter && foundFilterValue) {
        return {
            type: foundFilter.type,
            value: foundFilterValue
        }
    }
    return undefined
}

export function mapToWaitlistFiltersOpenApi(filter: ActionsFilterTypeAndValueType<WaitlistFilterType> | undefined): WaitlistFiltersOpenApi | undefined {
    if (filter === undefined) return undefined

    switch (filter.type) {
        case "ID":
            return { id: filter.value }
        case "AI_STATUS_DECLARED":
            return { aiStatusDeclared: filter.value }
        case "CONFIRMED":
            return { confirmed: filter.value }
        case "EMAIL":
            return { email: filter.value }
        case "FIRST_NAME":
            return { firstName: filter.value }
        case "LAST_NAME":
            return { lastName: filter.value }
        case "REFERRAL_CODE":
            return { referralCode: filter.value }
    }
}