import React, {useContext, useEffect, useMemo, useState} from "react"
import LoadingDots from "../../tech/loading/dots/dots.component"
import Section from "../../tech/section/section.component"
import SectionHeading from "../../tech/section/section-heading.component"
import tableStyles from "../../tech/table/table.module.css"
import Alert from "../../tech/alert/alert.component"
import {AlertType} from "../../tech/alert/type.enum"
import {useNavigate, useSearchParams} from "react-router-dom"
import {FetchClient} from "../../tech/fetch/client"
import FetchContext from "../../tech/fetch/fetch.context"
import Button from "../../tech/button/button.component"
import {ButtonStyle} from "../../tech/button/style.enum"
import {COMPANY, COMPANY_NEW} from "../../paths"
import {CompanyType} from "./company.type"
import {DateTime} from "luxon"
import {PaginatedType} from "../../tech/actions/pagination/pagination.type"
import {emptyPaginated, getPagination} from "../../tech/actions/pagination/pagination.util"
import {assembleSortOptions, getCurrentSortOption} from "./sort.util"
import {assembleFilterOptions, getCurrentFilter, mapToCompanyFiltersOpenApi} from "./filter.util"
import Actions from "../../tech/actions/actions.component"

const CompanyOverview = () => {
    const COMPANIES_PER_PAGE = 50
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const fetchClient = useContext<FetchClient>(FetchContext)
    const [state, setState] = useState<"LOADING" | "LOADED" | "ERROR">("LOADING")
    const [
        companies,
        setCompanies
    ] = useState<PaginatedType<CompanyType>>(emptyPaginated)

    const sortOptions = assembleSortOptions()
    const currentSortOption = useMemo(
        () => getCurrentSortOption(sortOptions, searchParams),
        [sortOptions, searchParams]
    )

    const filters = useMemo(() => assembleFilterOptions(), [])
    const currentFilter = useMemo(
        () => getCurrentFilter(filters, searchParams),
        [filters, searchParams]
    )

    const pagination = useMemo(() => getPagination(COMPANIES_PER_PAGE, searchParams), [searchParams])

    useEffect(() => {
        const fetch = async () => {
            try {
                setCompanies(await fetchClient.companyApi.getAllPaginated(
                    currentSortOption.id,
                    mapToCompanyFiltersOpenApi(currentFilter),
                    pagination
                ))
                setState("LOADED")
            } catch (err) {
                console.error(err)
                setState("ERROR")
            }
        }
        fetch()
    }, [fetchClient, currentFilter, currentSortOption.id, pagination])

    return (
        <Section>
            <SectionHeading
                title="Companies"
                subTitle={`${companies.total} companies`}
                button={<Button
                    title="New Company"
                    style={ButtonStyle.SECONDARY}
                    onClick={() => navigate(COMPANY_NEW)}
                    type="button"
                />}
            />
            <Actions
                filter={{
                    current: currentFilter,
                    filters
                }}
                pagination={{
                    label: "companies",
                    elementsPerPage: COMPANIES_PER_PAGE,
                    numberElements: companies.total
                }}
                sort={{
                    current: currentSortOption,
                    options: sortOptions
                }}
            >
                {state === "LOADING" && <LoadingDots/>}
                {state === "LOADED" && (
                    <div className={tableStyles.tableWrapper}>
                        <table className={tableStyles.tablePointer}>
                            <thead>
                            <tr>
                                <th>Legal Name</th>
                                <th>Created</th>
                                <th>Modified</th>
                                <th>Number of contacts</th>
                                <th>Deals</th>
                                <th>ID</th>
                            </tr>
                            </thead>
                            <tbody>
                            {companies.elements.map((company, i) => (
                                <tr
                                    key={i}
                                    onClick={() => navigate(COMPANY(company.id!))}
                                >
                                    <td>{company.legalName}</td>
                                    <td>{DateTime.fromJSDate(company.created!).toLocaleString(DateTime.DATETIME_MED)}</td>
                                    <td>{DateTime.fromJSDate(company.modified!).toLocaleString(DateTime.DATETIME_MED)}</td>
                                    <td>{company.contacts.length}</td>
                                    <td className={tableStyles.cellList}>
                                        {company.deals ? (
                                            <ul>
                                                {company.deals.map(deal => (
                                                    <li key={`company-${company.id}-deal-${deal.id}`}>
                                                        {deal.name.completeOnboarding}
                                                    </li>
                                                ))}
                                            </ul>
                                        ) : "-"}
                                    </td>
                                    <td>{company.id}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                )}
                {state === "ERROR" && (
                    <Alert
                        type={AlertType.ERROR}
                        text="Failed to load companies"
                    />
                )}
            </Actions>
        </Section>
    )
}

export default CompanyOverview