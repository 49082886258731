import React, {useContext, useEffect, useState} from "react"
import {useNavigate, useParams} from "react-router-dom"
import {FetchClient} from "../../tech/fetch/client"
import FetchContext from "../../tech/fetch/fetch.context"
import LoadingDots from "../../tech/loading/dots/dots.component"
import Alert from "../../tech/alert/alert.component"
import {AlertType} from "../../tech/alert/type.enum"
import Section from "../../tech/section/section.component"
import SectionHeading from "../../tech/section/section-heading.component"
import CompanyForm from "./form.component"
import {SubmitHandler} from "react-hook-form"
import {COMPANIES, COMPANY_DELETE} from "../../paths"
import Button from "../../tech/button/button.component"
import {ButtonStyle} from "../../tech/button/style.enum"
import {normalizeValues} from "../../tech/form/form.util"
import {CompanyType} from "./company.type"
import {CompanyContactType} from "./contact/contact.type"
import {DealType} from "../deal/deal.type"

const CompanyEdit = () => {
    let { id } = useParams()
    const navigate = useNavigate()
    const fetchClient = useContext<FetchClient>(FetchContext)
    const [state, setState] = useState<"LOADING" | "LOADED" | "ERROR">("LOADING")
    const [company, setCompany] = useState<CompanyType>()
    const [contacts, setContacts] = useState<CompanyContactType[]>([])
    const [deals, setDeals] = useState<DealType[]>([])

    useEffect(() => {
        const fetch = async () => {
            try {
                const [
                    fetchedCompany,
                    fetchedContacts,
                    fetchedDeals,
                ] = await Promise.all([
                    fetchClient.companyApi.get(id!),
                    fetchClient.companyContactApi.getAll(),
                    fetchClient.dealApi.getAll(),
                ])
                setCompany(fetchedCompany)
                setContacts(fetchedContacts)
                setDeals(fetchedDeals)
                setState("LOADED")
            } catch (err) {
                console.error(err)
                setState("ERROR")
            }
        }
        fetch()
    }, [fetchClient, id])

    const onSubmit: SubmitHandler<CompanyType> = async (company) => {
        setState("LOADING")
        try {
            await fetchClient.companyApi.update(id!, normalizeValues(company))
            navigate(COMPANIES)
        } catch (err) {
            console.error(err)
            setState("ERROR")
        }
    }

    return (
        <>
            {state === "LOADING" && <LoadingDots/>}
            {state === "ERROR" && (
                <Alert
                    type={AlertType.ERROR}
                    text="Unknown error."
                />
            )}
            {(state === "LOADED" || state === "ERROR") && company && (
                <Section>
                    <SectionHeading
                        title={`Edit company: ${company.legalName}`}
                        button={<Button
                            title="Delete"
                            style={ButtonStyle.DELETE}
                            onClick={() => navigate(COMPANY_DELETE(id!))}
                            type="button"
                        />}
                    />
                    <CompanyForm
                        company={company}
                        contacts={contacts}
                        deals={deals}
                        onSubmit={onSubmit}
                        submitText="Save company"
                    />
                </Section>
            )}
        </>
    )
}

export default CompanyEdit