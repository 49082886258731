/* tslint:disable */
/* eslint-disable */
/**
 * NonPublic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { InvestmentOpenApi } from './InvestmentOpenApi';
import {
    InvestmentOpenApiFromJSON,
    InvestmentOpenApiFromJSONTyped,
    InvestmentOpenApiToJSON,
} from './InvestmentOpenApi';
import type { ManualReconciliationBankAccountOpenApi } from './ManualReconciliationBankAccountOpenApi';
import {
    ManualReconciliationBankAccountOpenApiFromJSON,
    ManualReconciliationBankAccountOpenApiFromJSONTyped,
    ManualReconciliationBankAccountOpenApiToJSON,
} from './ManualReconciliationBankAccountOpenApi';

/**
 * 
 * @export
 * @interface ManualReconciliationLogOpenApi
 */
export interface ManualReconciliationLogOpenApi {
    /**
     * 
     * @type {string}
     * @memberof ManualReconciliationLogOpenApi
     */
    id?: string;
    /**
     * 
     * @type {Date}
     * @memberof ManualReconciliationLogOpenApi
     */
    created?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ManualReconciliationLogOpenApi
     */
    modified?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ManualReconciliationLogOpenApi
     */
    received: Date;
    /**
     * 
     * @type {number}
     * @memberof ManualReconciliationLogOpenApi
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof ManualReconciliationLogOpenApi
     */
    comment?: string;
    /**
     * 
     * @type {InvestmentOpenApi}
     * @memberof ManualReconciliationLogOpenApi
     */
    investment: InvestmentOpenApi;
    /**
     * 
     * @type {ManualReconciliationBankAccountOpenApi}
     * @memberof ManualReconciliationLogOpenApi
     */
    bankAccount: ManualReconciliationBankAccountOpenApi;
}

/**
 * Check if a given object implements the ManualReconciliationLogOpenApi interface.
 */
export function instanceOfManualReconciliationLogOpenApi(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "received" in value;
    isInstance = isInstance && "amount" in value;
    isInstance = isInstance && "investment" in value;
    isInstance = isInstance && "bankAccount" in value;

    return isInstance;
}

export function ManualReconciliationLogOpenApiFromJSON(json: any): ManualReconciliationLogOpenApi {
    return ManualReconciliationLogOpenApiFromJSONTyped(json, false);
}

export function ManualReconciliationLogOpenApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): ManualReconciliationLogOpenApi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'modified': !exists(json, 'modified') ? undefined : (new Date(json['modified'])),
        'received': (new Date(json['received'])),
        'amount': json['amount'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'investment': InvestmentOpenApiFromJSON(json['investment']),
        'bankAccount': ManualReconciliationBankAccountOpenApiFromJSON(json['bankAccount']),
    };
}

export function ManualReconciliationLogOpenApiToJSON(value?: ManualReconciliationLogOpenApi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'created': value.created === undefined ? undefined : (value.created.toISOString()),
        'modified': value.modified === undefined ? undefined : (value.modified.toISOString()),
        'received': (value.received.toISOString()),
        'amount': value.amount,
        'comment': value.comment,
        'investment': InvestmentOpenApiToJSON(value.investment),
        'bankAccount': ManualReconciliationBankAccountOpenApiToJSON(value.bankAccount),
    };
}

