import React, {FunctionComponent} from "react"
import {useNavigate} from "react-router-dom"
import {DateTime} from "luxon"
import {mapDealOutcomeToString} from "../../deal/deal.type"
import {VALUATIONS_FOR_DEAL} from "../../../paths"
import tableStyles from "../../../tech/table/table.module.css"
import {DealWithValuationsType} from "../../deal/valuation/deal-with-valuations.type"

type ValuationOverviewDealsTableProps = {
    dealWithValuations: DealWithValuationsType[]
}

const ValuationOverviewDealsTable: FunctionComponent<ValuationOverviewDealsTableProps> = ({
    dealWithValuations
}) => {
    const navigate = useNavigate()

    return (
        <div className={tableStyles.tableWrapper}>
            <table className={tableStyles.tablePointer}>
                <thead>
                <tr>
                    <th>Deal</th>
                    <th>Deal State</th>
                    <th>Number of valuations</th>
                </tr>
                </thead>
                <tbody>
                {dealWithValuations
                    .map((dealWithValuations, i) => {
                        return (
                            <tr
                                key={i}
                                onClick={() => navigate(VALUATIONS_FOR_DEAL(dealWithValuations.deal.id!))}
                            >
                                <td>
                                    {dealWithValuations.deal.name.completeOnboarding} ({dealWithValuations.deal.singlePurposeVehicle.name},{" "}
                                    {DateTime.fromJSDate(dealWithValuations.deal.finalDeadline).toLocaleString(DateTime.DATE_MED)})
                                </td>
                                <td>
                                    {mapDealOutcomeToString(dealWithValuations.deal.outcome)}
                                </td>
                                <td>
                                    {dealWithValuations.valuations.length}
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}

export default ValuationOverviewDealsTable