import {SyndicateApiClient, SyndicateFiltersOpenApi, SyndicateSortOpenApi} from "../../generated"
import {PaginatedType, PaginationType} from "../../tech/actions/pagination/pagination.type"
import {mapOpenApiToSyndicate, mapSyndicateToOpenApi} from "./syndicate.mapper"
import {SyndicateType} from "./syndicate.type"
import {ExtractedResponse, FetchResponse} from "../../tech/response/response.type"
import {extractError} from "../../tech/response/extract.util"
import {SyndicateInvitationType} from "./invitation/invitation.type"
import {mapOpenApiToSyndicateInvitation, mapSyndicateInvitationToOpenApi} from "./invitation/invitation.mapper"
import {mapOpenApiToInvestorWithDetails} from "../investor/investor.mapper"
import {InvestorWithDetailsType} from "../investor/investor.type"

export class SyndicateApi {

    constructor(private apiClient: SyndicateApiClient) {
    }

    public async create(syndicate: SyndicateType): Promise<SyndicateType> {
        const savedSyndicate = await this.apiClient.syndicateCreate({ syndicateOpenApi: mapSyndicateToOpenApi(syndicate) })
        return mapOpenApiToSyndicate(savedSyndicate)
    }

    public async delete(id: string): Promise<ExtractedResponse<void>> {
        try {
            return new FetchResponse(await this.apiClient.syndicateDelete({ id }))
        }
        catch (err) {
            return await extractError(err)
        }
    }

    public async get(id: string): Promise<SyndicateType> {
        const syndicate = await this.apiClient.syndicateGet({ id })
        return mapOpenApiToSyndicate(syndicate)
    }

    public async getAllPaginated(
        sort: SyndicateSortOpenApi,
        filters?: SyndicateFiltersOpenApi,
        pagination?: PaginationType
    ): Promise<PaginatedType<SyndicateType>> {
        const result = await this.apiClient.syndicateGetAllPaginated({
            syndicateFiltersOpenApi: filters,
            elementsPerPage: pagination?.elementsPerPage,
            page: pagination?.page,
            sort
        })
        return {
            elements: result.syndicates.map(mapOpenApiToSyndicate),
            total: result.total
        }
    }

    public async update(id: string, syndicate: SyndicateType): Promise<SyndicateType> {
        const response = await this.apiClient.syndicateUpdate({ id, syndicateOpenApi: mapSyndicateToOpenApi(syndicate) })
        return mapOpenApiToSyndicate(response)
    }

    public async createNewInvitation(invitation: SyndicateInvitationType): Promise<ExtractedResponse<SyndicateInvitationType>> {
        try {
            return new FetchResponse(mapOpenApiToSyndicateInvitation(await this.apiClient.syndicateInvitationCreate({
                syndicateId: invitation.syndicate.id!,
                syndicateInvitationOpenApi: mapSyndicateInvitationToOpenApi(invitation)
            })))
        }
        catch (err) {
            return await extractError(err)
        }
    }

    public async deleteInvitation(syndicateId: string, invitationId: string): Promise<void> {
        await this.apiClient.syndicateInvitationDelete({ syndicateId, invitationId })
    }

    public async getInvitation(syndicateId: string, invitationId: string): Promise<SyndicateInvitationType> {
        const res = await this.apiClient.syndicateInvitationGet({ syndicateId, invitationId })
        return mapOpenApiToSyndicateInvitation(res)
    }

    public async getAllInvitationsNotAlreadyEntered(syndicateId: string): Promise<SyndicateInvitationType[]> {
        return (await this.apiClient.syndicateInvitationGetAllNotAlreadyEntered({ syndicateId }))
            .map(mapOpenApiToSyndicateInvitation)
    }

    public async resendInvitation(syndicateId: string, invitationId: string): Promise<void> {
        await this.apiClient.syndicateInvitationResend({ syndicateId, invitationId })
    }

    public async getAllMembers(syndicateId: string): Promise<InvestorWithDetailsType[]> {
        return (await this.apiClient.syndicateMemberGetAll({ syndicateId }))
            .map(mapOpenApiToInvestorWithDetails)
    }

    public async removeMember(syndicateId: string, investorId: string): Promise<void> {
        await this.apiClient.syndicateMemberRemove({
            syndicateId,
            investorId
        })
    }

}