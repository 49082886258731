import React, {FunctionComponent} from "react"
import {SubmitHandler, useForm} from "react-hook-form"
import formStyles from "../../../tech/form/form.module.css"
import {CompanyContactType} from "./contact.type"
import FormError from "../../../tech/form/error.component"
import {CompanyContactTypeOpenApi} from "../../../generated"

type CompanyContactFormProps = {
    contact?: CompanyContactType
    onSubmit: SubmitHandler<CompanyContactType>
    submitText: string
}

const CompanyContactForm: FunctionComponent<CompanyContactFormProps> = ({
    contact,
    onSubmit,
    submitText
}) => {
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm<CompanyContactType>({
        defaultValues: contact
    })

    return (
        <form
            className={formStyles.form}
            onSubmit={handleSubmit(onSubmit)}
        >
            <input type="hidden" {...register("id")} />

            <label>
                <span>Name</span>
                <input
                    type="text"
                    placeholder="Name"
                    {...register("name", {
                        required: "Name is required.",
                        minLength: {value: 3, message: "Too short."}
                    })}
                />
                <FormError field="name" errors={errors}/>
            </label>

            <label>
                <span>Types</span>
                <select
                    {...register("types")}
                    multiple
                >
                    {Object.entries(CompanyContactTypeOpenApi).map((t, i) => (
                        <option value={t[1]} key={i}>{t[1]}</option>
                    ))}
                </select>
                <FormError field="types" errors={errors}/>
            </label>

            <h3>Contact details</h3>
            <label>
                <span>Email</span>
                <input
                    type="text"
                    placeholder="Email"
                    {...register("contact.email", {
                        pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "Invalid email."
                        }
                    })}
                />
                <FormError field="contact.email" errors={errors}/>
            </label>

            <label>
                <span>Phone</span>
                <input
                    type="text"
                    placeholder="Phone"
                    {...register("contact.phone")}
                />
                <FormError field="contact.phone" errors={errors}/>
            </label>

            <label>
                <span>LinkedIn</span>
                <input
                    type="text"
                    placeholder="https://www.linkedin.com/in/jane-doe"
                    {...register("contact.linkedIn", {
                        pattern: {
                            value: /^(https?:\/\/)?(www\.)?linkedin\.com\/(in|pub)\/[a-zA-Z0-9_-]+(\/)?$/,
                            message: "Please enter a valid LinkedIn profile URL."
                        }
                    })}
                />
                <FormError field="contact.linkedIn" errors={errors}/>
            </label>

            <button
                type="submit"
                className={formStyles.submitButton}
            >
                {submitText}
            </button>
        </form>
    )
}

export default CompanyContactForm