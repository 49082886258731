import {DealCategoryOpenApi} from "../../generated"
import {DealCategoryType} from "./deal-category.type"

export function mapOpenApiToDealCategory(category: DealCategoryOpenApi): DealCategoryType {
    return {
        id: category.id,
        name: category.name,
        slug: category.slug
    }
}

export function mapDealCategoryToOpenApi(category: DealCategoryType): DealCategoryOpenApi {
    return {
        id: category.id,
        name: category.name,
        slug: category.slug
    }
}