import {InvestorGroupOpenApi} from "../../generated"
import {InvestorGroupType} from "./investor-group.type"

export function mapOpenApiToInvestorGroup(group: InvestorGroupOpenApi): InvestorGroupType {
    return {
        id: group.id,
        name: group.name,
        description: group.description
    }
}

export function mapInvestorGroupToOpenApi(group: InvestorGroupType): InvestorGroupOpenApi {
    return {
        id: group.id,
        name: group.name,
        description: group.description
    }
}