import React, {useContext, useEffect, useState} from "react"
import {useNavigate, useParams} from "react-router-dom"
import Section from "../../../tech/section/section.component"
import {FetchClient} from "../../../tech/fetch/client"
import FetchContext from "../../../tech/fetch/fetch.context"
import LoadingDots from "../../../tech/loading/dots/dots.component"
import Alert from "../../../tech/alert/alert.component"
import {AlertType} from "../../../tech/alert/type.enum"
import Button from "../../../tech/button/button.component"
import {ButtonStyle} from "../../../tech/button/style.enum"
import {INVESTORS} from "../../../paths"
import {InvestorWithDetailsType} from "../investor.type"

const InvestorDelete = () => {
    let { id } = useParams()
    const navigate = useNavigate()
    const fetchClient = useContext<FetchClient>(FetchContext)
    const [state, setState] = useState<"LOADING" | "LOADED" | "ERROR">("LOADING")
    const [
        investorWithDetails,
        setInvestorWithDetails
    ] = useState<InvestorWithDetailsType>()

    useEffect(() => {
        const fetch = async () => {
            try {
                setInvestorWithDetails(await fetchClient.investorApi.get(id!))
                setState("LOADED")
            } catch (err) {
                console.error(err)
                setState("ERROR")
            }
        }
        fetch()
    }, [fetchClient, id])

    const del = async () => {
        setState("LOADING")
        try {
            await fetchClient.investorApi.delete(id!)
            navigate(INVESTORS)
        } catch (err) {
            console.error(err)
            setState("ERROR")
        }
    }

    return (
        <>
            {state === "LOADING" && <LoadingDots/>}
            {state === "LOADED" && investorWithDetails && (
                <Section>
                    <div className="mb-5">
                        Do you really want to delete investor <strong>{investorWithDetails.investor.person?.firstName} {investorWithDetails.investor.person?.lastName}</strong> with id <strong>{investorWithDetails.investor.id}</strong>?
                    </div>
                    <Button
                        title="Delete permanently"
                        style={ButtonStyle.DELETE}
                        onClick={del}
                        type={"button"}
                    />
                </Section>
            )}
            {state === "ERROR" && (
                <Alert
                    type={AlertType.ERROR}
                    text="Unknown error."
                />
            )}
        </>
    )
}

export default InvestorDelete