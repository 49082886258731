import React, {useContext, useEffect, useState} from "react"
import {useNavigate, useParams} from "react-router-dom"
import {FetchClient} from "../../../tech/fetch/client"
import FetchContext from "../../../tech/fetch/fetch.context"
import LoadingDots from "../../../tech/loading/dots/dots.component"
import Alert from "../../../tech/alert/alert.component"
import {AlertType} from "../../../tech/alert/type.enum"
import Section from "../../../tech/section/section.component"
import SectionHeading from "../../../tech/section/section-heading.component"
import ManualReconciliationBankAccountForm from "./form.component"
import {SubmitHandler} from "react-hook-form"
import {MANUAL_RECONCILIATION_BANK_ACCOUNT_DELETE, MANUAL_RECONCILIATION_BANK_ACCOUNTS} from "../../../paths"
import Button from "../../../tech/button/button.component"
import {ButtonStyle} from "../../../tech/button/style.enum"
import {normalizeValues} from "../../../tech/form/form.util"
import {ManualReconciliationBankAccountWithDealsType} from "./manual-reconciliation-bank-account.type"
import {SinglePurposeVehicleSortOpenApi} from "../../../generated"
import {SinglePurposeVehicleType} from "../../single-purpose-vehicle/single-purpose-vehicle.type"

const ManualReconciliationBankAccountEdit = () => {
    let { id } = useParams()
    const navigate = useNavigate()
    const fetchClient = useContext<FetchClient>(FetchContext)
    const [state, setState] = useState<"LOADING" | "LOADED" | "ERROR">("LOADING")
    const [account, setAccount] = useState<ManualReconciliationBankAccountWithDealsType>()
    const [singlePurposeVehicles, setSinglePurposeVehicles] = useState<SinglePurposeVehicleType[]>([])

    useEffect(() => {
        const fetch = async () => {
            try {
                const [a, spvs] = await Promise.all([
                    fetchClient.reconciliationApi.getManualReconciliationBankAccount(id!),
                    fetchClient.singlePurposeVehicleApi.getAllPaginated(SinglePurposeVehicleSortOpenApi.NameAscending)
                ])
                setAccount(a)
                setSinglePurposeVehicles(spvs.elements)
                setState("LOADED")
            } catch (err) {
                console.error(err)
                setState("ERROR")
            }
        }
        fetch()
    }, [fetchClient, id])

    const onSubmit: SubmitHandler<ManualReconciliationBankAccountWithDealsType> = async (account) => {
        setState("LOADING")
        try {
            await fetchClient.reconciliationApi.updateManualReconciliationBankAccount(id!, normalizeValues(account))
            navigate(MANUAL_RECONCILIATION_BANK_ACCOUNTS)
        } catch (err) {
            console.error(err)
            setState("ERROR")
        }
    }

    return (
        <>
            {state === "LOADING" && <LoadingDots/>}
            {state === "ERROR" && (
                <Alert
                    type={AlertType.ERROR}
                    text="Unknown error."
                />
            )}
            {(state === "LOADED" || state === "ERROR") && account && (
                <Section>
                    <SectionHeading
                        title={`Edit Manual Reconciliation Bank Account: ${account.number} (${account.holderSinglePurposeVehicle.name})`}
                        button={<Button
                            title="Delete"
                            style={ButtonStyle.DELETE}
                            onClick={() => navigate(MANUAL_RECONCILIATION_BANK_ACCOUNT_DELETE(id!))}
                            type="button"
                        />}
                    />
                    <ManualReconciliationBankAccountForm
                        account={account}
                        singlePurposeVehicles={singlePurposeVehicles}
                        onSubmit={onSubmit}
                        submitText="Save Manual Reconciliation Bank Account"
                    />
                </Section>
            )}
        </>
    )
}

export default ManualReconciliationBankAccountEdit