import React, {useContext, useEffect, useState} from "react"
import {Link} from "react-router-dom"
import Section from "../../tech/section/section.component"
import SectionHeading from "../../tech/section/section-heading.component"
import {FetchClient} from "../../tech/fetch/client"
import FetchContext from "../../tech/fetch/fetch.context"
import {CollectionDeviationType} from "../collection/deviation/collection-deviation.type"
import {CollectionDeviationStateOpenApi} from "../../generated"
import {KycLogType} from "../kyc/log/kyc-log.type"
import LoadingDots from "../../tech/loading/dots/dots.component"
import Alert from "../../tech/alert/alert.component"
import {AlertType} from "../../tech/alert/type.enum"
import {ASYNCHRONOUS_ACTION_OVERVIEW, COLLECTION_DEVIATIONS, KYC_REVIEWS} from "../../paths"
import styles from "./dashboard.module.css"
import DashboardDealsWithoutValuation from "./deals-without-valuation.component"
import {AsynchronousActionOverviewType} from "../../tech/asynchronous/action/overview/overview.type"
import {countErrors} from "../../tech/asynchronous/action/overview/overview.util"
import DashboardDealsWithoutCompany from "./deals-without-company.component"

const Dashboard = () => {
    const fetchClient = useContext<FetchClient>(FetchContext)
    const [state, setState] = useState<"LOADING" | "LOADED" | "ERROR">("LOADING")
    const [actionOverview, setActionOverview] = useState<AsynchronousActionOverviewType>()
    const [openCollectionDeviations, setOpenCollectionDeviations] = useState<CollectionDeviationType[]>([])
    const [openKycReviews, setOpenKycReviews] = useState<KycLogType[]>([])

    useEffect(() => {
        const fetch = async () => {
            try {
                const [fetchedActionOverview, fetchedOpenCollectionDeviations, fetchedOpenKycReviews] = await Promise.all([
                    fetchClient.actionApi.getOverview(),
                    fetchClient.collectionApi.getAllCollectionDeviations(undefined, CollectionDeviationStateOpenApi.Open),
                    fetchClient.kycApi.getAllKycLogsToBeReviewed()
                ])
                setActionOverview(fetchedActionOverview)
                setOpenCollectionDeviations(fetchedOpenCollectionDeviations)
                setOpenKycReviews(fetchedOpenKycReviews)
                setState("LOADED")
            } catch (err) {
                console.error(err)
                setState("ERROR")
            }
        }
        fetch()
    }, [fetchClient])

    const errorCount = actionOverview && countErrors(actionOverview)

    return (
        <Section>
            <SectionHeading title="Dashboard"/>
            {state === "LOADING" && <LoadingDots/>}
            {state === "LOADED" && (
                <div className={styles.dashboard}>
                    <div className={openCollectionDeviations.length > 0 ? styles.boxRed : styles.box}>
                        <div>
                            <h3>Collection deviations</h3>
                            {openCollectionDeviations.length > 0 ? (
                                <>
                                    <strong>
                                        {openCollectionDeviations.length} collection deviation(s) need to be handled.
                                    </strong>
                                    <Link to={`${COLLECTION_DEVIATIONS}?state=OPEN`}>
                                        &rarr; Go to collection deviations
                                    </Link>
                                </>
                            ) : (
                                <>No unresolved collection deviations.</>
                            )}
                        </div>
                    </div>
                    <div className={openKycReviews.length > 0 ? styles.boxRed : styles.box}>
                        <div>
                            <h3>Open KYC reviews</h3>
                            {openKycReviews.length > 0 ? (
                                <>
                                    <strong>
                                        {openKycReviews.length} KYC document(s) need to be reviewed.
                                    </strong>
                                    <Link to={KYC_REVIEWS}>
                                        &rarr; Go to open KYC reviews
                                    </Link>
                                </>
                            ) : (
                                <>No open KYC reviews.</>
                            )}
                        </div>
                    </div>
                    <div className={errorCount && errorCount > 0 ? styles.boxRed : styles.box}>
                        <div>
                            <h3>Asynchronous Action Errors</h3>
                            {errorCount && errorCount > 0 ? (
                                <>
                                    <strong>
                                        {errorCount} Error(s).
                                    </strong>
                                    <Link to={ASYNCHRONOUS_ACTION_OVERVIEW}>
                                        &rarr; Go to asynchronous action overview
                                    </Link>
                                </>
                            ) : (
                                <>No errors.</>
                            )}
                        </div>
                    </div>
                    <DashboardDealsWithoutValuation/>
                    <DashboardDealsWithoutCompany/>
                </div>
            )}
            {state === "ERROR" && (
                <Alert
                    type={AlertType.ERROR}
                    text="Failed to load data"
                />
            )}
        </Section>
    )
}

export default Dashboard