import {PersonOpenApi} from "../../generated"
import {PersonType} from "./person.type"
import {mapAddressToOpenApi, mapOpenApiToAddress} from "../address/address.mapper"
import {mapContactToOpenApi, mapOpenApiToContact} from "../contact/contact.mapper"

export function mapOpenApiToPerson(person: PersonOpenApi): PersonType {
    return {
        salutation: person.salutation,
        firstName: person.firstName,
        lastName: person.lastName,
        fullName: person.fullName,
        address: person.address && mapOpenApiToAddress(person.address),
        contact: mapOpenApiToContact(person.contact!)
    }
}

export function mapPersonToOpenApi(person: PersonType): PersonOpenApi {
    return {
        salutation: person.salutation,
        firstName: person.firstName,
        lastName: person.lastName,
        fullName: person.fullName,
        address: mapAddressToOpenApi(person.address!),
        contact: mapContactToOpenApi(person.contact)
    }
}