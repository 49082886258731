import {LoggingApiClient} from "../../generated"

export class LoggingApi {

    constructor(private apiClient: LoggingApiClient) {
    }

    public async sendError(
        date: Date,
        error: string,
        frontendUrl: string
    ): Promise<void> {
        await this.apiClient.logBackofficeError({
            javaScriptErrorOpenApi: {
                date,
                error: this.enforceString(error),
                frontendUrl
            }
        })
    }

    public async sendRequestError(
        date: Date,
        error: string|undefined,
        frontendUrl: string,
        requestUrl: string,
        responseStatus: number|undefined
    ): Promise<void> {
        await this.apiClient.logBackofficeRequestError({
            javaScriptRequestErrorOpenApi: {
                date,
                error: this.enforceString(error),
                frontendUrl,
                requestUrl,
                responseStatus
            }
        })
    }

    private enforceString(err: any): string {
        return err === undefined
            ? ""
            : err.toString()
    }

}