import React, {useContext, useEffect, useState} from "react"
import {useParams} from "react-router-dom"
import LoadingDots from "../../../../tech/loading/dots/dots.component"
import Alert from "../../../../tech/alert/alert.component"
import {AlertType} from "../../../../tech/alert/type.enum"
import {FetchClient} from "../../../../tech/fetch/client"
import FetchContext from "../../../../tech/fetch/fetch.context"
import SectionHeading from "../../../../tech/section/section-heading.component"
import Section from "../../../../tech/section/section.component"
import {InvestorWithDetailsType} from "../../investor.type"
import {KycLogType} from "../../../kyc/log/kyc-log.type"
import KycLogsTable from "../../../kyc/log/table.component"

const InvestorKycLogs = () => {
    let { id } = useParams()
    const fetchClient = useContext<FetchClient>(FetchContext)
    const [state, setState] = useState<"LOADING" | "LOADED" | "ERROR">("LOADING")
    const [investorWithDetails, setInvestorWithDetails] = useState<InvestorWithDetailsType>()
    const [kycLogs, setKycLogs] = useState<KycLogType[]>([])

    useEffect(() => {
        const fetch = async () => {
            try {
                const investorId = id!
                const [investor, kycLogs] = await Promise.all([
                    fetchClient.investorApi.get(investorId),
                    fetchClient.investorApi.getAllKycLogs(investorId)
                ])
                setInvestorWithDetails(investor)
                setKycLogs(kycLogs)
                setState("LOADED")
            } catch (err) {
                console.error(err)
                setState("ERROR")
            }
        }
        fetch()
    }, [fetchClient, id])

    return (
        <>
            {state === "LOADING" && <LoadingDots/>}
            {state === "LOADED" && investorWithDetails && kycLogs && (
                <Section>
                    <SectionHeading
                        title={`Investor: ${investorWithDetails.investor.person?.firstName} ${investorWithDetails.investor.person?.lastName} (KYC Logs)`}
                    />
                    <KycLogsTable
                        kycLogs={kycLogs}
                    />
                </Section>
            )}
            {state === "ERROR" && (
                <Alert
                    type={AlertType.ERROR}
                    text="Failed to load KYC logs"
                />
            )}
        </>
    )
}

export default InvestorKycLogs