import {InvestorKycType, InvestorType, InvestorWithDetailsType} from "./investor.type"
import {InvestorKycOpenApi, InvestorOpenApi, InvestorWithDetailsOpenApi} from "../../generated"
import {mapOpenApiToUser, mapUserToOpenApi} from "../user/user.mapper"
import {mapDealGroupToOpenApi, mapOpenApiToDealGroup} from "../deal-group/deal-group.mapper"
import {mapOpenApiToPerson, mapPersonToOpenApi} from "../person/person.mapper"
import {mapInvestorGroupToOpenApi, mapOpenApiToInvestorGroup} from "../investor-group/investor-group.mapper"
import {mapOpenApiToSubscription} from "../subscription/subscription.mapper"

export function mapOpenApiToInvestorWithDetails(
    investorWithDetails: InvestorWithDetailsOpenApi
): InvestorWithDetailsType {
    return {
        investor: mapOpenApiToInvestor(investorWithDetails.investor),
        subscription: investorWithDetails.subscription
            && mapOpenApiToSubscription(investorWithDetails.subscription)
    }
}

export function mapOpenApiToInvestor(investor: InvestorOpenApi): InvestorType {
    return {
        id: investor.id,
        created: investor.created,
        modified: investor.modified,
        user: investor.user && mapOpenApiToUser(investor.user),
        person: investor.person && mapOpenApiToPerson(investor.person),
        dealGroups: investor.dealGroups!.map(g => mapOpenApiToDealGroup(g)),
        investorGroups: investor.investorGroups!.map(g => mapOpenApiToInvestorGroup(g)),
        aiStatusDeclared: investor.aiStatusDeclared,
        kyc: investor.kyc && mapOpenApiToInvestorKyc(investor.kyc),
        started: investor.started,
        imported: investor.imported,
        companyName: investor.companyName,
        stripeCustomerId: investor.stripeCustomerId
    }
}

export function mapInvestorToOpenApi(investor: InvestorType): InvestorOpenApi {
    return {
        id: investor.id,
        created: investor.created,
        modified: investor.modified,
        user: investor.user && mapUserToOpenApi(investor.user),
        person: investor.person && mapPersonToOpenApi(investor.person),
        dealGroups: investor.dealGroups && investor.dealGroups.map(g => mapDealGroupToOpenApi(g)),
        investorGroups: investor.investorGroups && investor.investorGroups.map(g => mapInvestorGroupToOpenApi(g)),
        aiStatusDeclared: investor.aiStatusDeclared,
        kyc: investor.kyc && mapInvestorKycToOpenApi(investor.kyc),
        started: investor.started,
        imported: investor.imported,
        companyName: investor.companyName,
        stripeCustomerId: investor.stripeCustomerId
    }
}

export function mapOpenApiToInvestorKyc(kyc: InvestorKycOpenApi): InvestorKycType {
    return {
        passportValidTill: kyc.passportValidTill,
        proofOfAddressValidTill: kyc.proofOfAddressValidTill,
        proofOfAiStatusAvailable: kyc.proofOfAiStatusAvailable
    }
}

export function mapInvestorKycToOpenApi(kyc: InvestorKycType): InvestorKycOpenApi {
    return {
        passportValidTill: kyc.passportValidTill,
        proofOfAddressValidTill: kyc.proofOfAddressValidTill,
        proofOfAiStatusAvailable: kyc.proofOfAiStatusAvailable
    }
}