import {ManualReconciliationLogOpenApi} from "../../../generated"
import {ManualReconciliationLogType} from "./manual-reconciliation-log.type"
import {mapInvestmentToOpenApi, mapOpenApiToInvestment} from "../../investment/investment.mapper"
import {
    mapManualReconciliationBankAccountToOpenApi,
    mapOpenApiToManualReconciliationBankAccount
} from "../account/manual-reconciliation-bank-account.mapper"

export function mapOpenApiToManualReconciliationLog(log: ManualReconciliationLogOpenApi): ManualReconciliationLogType {
    return {
        id: log.id,
        created: log.created,
        modified: log.modified,
        received: log.received,
        amount: log.amount,
        comment: log.comment,
        investment: mapOpenApiToInvestment(log.investment),
        bankAccount: mapOpenApiToManualReconciliationBankAccount(log.bankAccount),
    }
}

export function mapManualReconciliationLogToOpenApi(log: ManualReconciliationLogType): ManualReconciliationLogOpenApi {
    return {
        id: log.id,
        created: log.created,
        modified: log.modified,
        received: log.received,
        amount: log.amount,
        comment: log.comment,
        investment: mapInvestmentToOpenApi(log.investment),
        bankAccount: mapManualReconciliationBankAccountToOpenApi(log.bankAccount)
    }
}