import React, {useContext, useEffect, useState} from "react"
import {useNavigate, useParams} from "react-router-dom"
import {FetchClient} from "../../tech/fetch/client"
import FetchContext from "../../tech/fetch/fetch.context"
import LoadingDots from "../../tech/loading/dots/dots.component"
import Alert from "../../tech/alert/alert.component"
import {AlertType} from "../../tech/alert/type.enum"
import Section from "../../tech/section/section.component"
import SectionHeading from "../../tech/section/section-heading.component"
import DealGroupForm from "./form.component"
import {SubmitHandler} from "react-hook-form"
import {DEAL_GROUP_DELETE, DEAL_GROUPS} from "../../paths"
import Button from "../../tech/button/button.component"
import {ButtonStyle} from "../../tech/button/style.enum"
import {DealGroupType} from "./deal-group.type"
import {normalizeValues} from "../../tech/form/form.util"

const DealGroupEdit = () => {
    let { id } = useParams()
    const navigate = useNavigate()
    const fetchClient = useContext<FetchClient>(FetchContext)
    const [state, setState] = useState<"LOADING" | "LOADED" | "ERROR">("LOADING")
    const [dealGroup, setDealGroup] = useState<DealGroupType>()

    useEffect(() => {
        const fetch = async () => {
            try {
                setDealGroup(await fetchClient.dealGroupApi.get(id!))
                setState("LOADED")
            } catch (err) {
                console.error(err)
                setState("ERROR")
            }
        }
        fetch()
    }, [fetchClient, id])

    const onSubmit: SubmitHandler<DealGroupType> = async (dealGroup) => {
        setState("LOADING")
        try {
            await fetchClient.dealGroupApi.update(id!, normalizeValues(dealGroup))
            navigate(DEAL_GROUPS)
        } catch (err) {
            console.error(err)
            setState("ERROR")
        }
    }

    return (
        <>
            {state === "LOADING" && <LoadingDots/>}
            {state === "ERROR" && (
                <Alert
                    type={AlertType.ERROR}
                    text="Unknown error."
                />
            )}
            {(state === "LOADED" || state === "ERROR") && dealGroup && (
                <Section>
                    <SectionHeading
                        title={`Edit deal group: ${dealGroup.name}`}
                        button={<Button
                            title="Delete"
                            style={ButtonStyle.DELETE}
                            onClick={() => navigate(DEAL_GROUP_DELETE(id!))}
                            type="button"
                        />}
                    />
                    <DealGroupForm
                        dealGroup={dealGroup}
                        onSubmit={onSubmit}
                        submitText="Save deal group"
                    />
                </Section>
            )}
        </>
    )
}

export default DealGroupEdit