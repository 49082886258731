import {
    DealApiClient,
    DealFiltersOpenApi,
    DealOpenApi,
    DealOutcomeOpenApi,
    DealSortOpenApi,
    DealWithValuationsOpenApi,
    DealWithValuationsSortOpenApi
} from "../../generated"
import {DealType} from "./deal.type"
import {mapDealToOpenApi, mapOpenApiToDeal} from "./deal.mapper"
import {mapOpenApiToDealQuestion} from "../deal-question/deal-question.mapper"
import {DealQuestionType} from "../deal-question/deal-question.type"
import {PaginatedType, PaginationType} from "../../tech/actions/pagination/pagination.type"
import {DealWithValuationsType} from "./valuation/deal-with-valuations.type"
import {mapOpenApiToDealWithValuations} from "./valuation/deal-with-valuations.mapper"
import {ExtractedResponse, FetchResponse} from "../../tech/response/response.type"
import {extractError} from "../../tech/response/extract.util"
import {DealPublicationValidationPreview} from "./edit/publish/validation.type"
import {mapOpenApiToDealPublicationValidationPreview} from "./edit/publish/validation.mapper"

export class DealApi {

    constructor(private apiClient: DealApiClient) {
    }

    public async create(deal: DealType): Promise<ExtractedResponse<DealType>> {
        try {
            return new FetchResponse(mapOpenApiToDeal(await this.apiClient.dealCreate({ dealOpenApi: mapDealToOpenApi(deal) })))
        }
        catch (err) {
            return await extractError(err)
        }
    }

    public async delete(id: string): Promise<ExtractedResponse<void>> {
        try {
            return new FetchResponse(await this.apiClient.dealDelete({ id }))
        }
        catch (err) {
            return await extractError(err)
        }
    }

    public async getAll(): Promise<DealType[]> {
        return (await this.apiClient.dealGetAll())
            .map((deal: DealOpenApi) => mapOpenApiToDeal(deal))
    }

    public async getAllPaginated(
        sort: DealSortOpenApi,
        filters?: DealFiltersOpenApi,
        pagination?: PaginationType
    ): Promise<PaginatedType<DealType>> {
        const result = await this.apiClient.dealGetAllPaginated({
            dealFiltersOpenApi: filters,
            elementsPerPage: pagination?.elementsPerPage,
            page: pagination?.page,
            sort
        })
        return {
            elements: result.deals.map((deal: DealOpenApi) => mapOpenApiToDeal(deal)),
            total: result.total
        }
    }

    public async getAllWithValuationsPaginated(
        sort: DealWithValuationsSortOpenApi,
        filters?: DealFiltersOpenApi,
        pagination?: PaginationType
    ): Promise<PaginatedType<DealWithValuationsType>> {
        const result = await this.apiClient.dealGetAllWithValuationsPaginated({
            dealFiltersOpenApi: filters,
            elementsPerPage: pagination?.elementsPerPage,
            page: pagination?.page,
            sort
        })
        return {
            elements: result.dealsWithValuations.map((dealWithValuations: DealWithValuationsOpenApi) => mapOpenApiToDealWithValuations(dealWithValuations)),
            total: result.total
        }
    }

    public async getAllWithoutCompany(): Promise<DealType[]> {
        return (await this.apiClient.dealGetAllWithoutCompany())
            .map((deal: DealOpenApi) => mapOpenApiToDeal(deal))
    }

    public async getAllWithoutValuation(): Promise<DealType[]> {
        return (await this.apiClient.dealGetAllWithoutValuation())
            .map((deal: DealOpenApi) => mapOpenApiToDeal(deal))
    }

    public async get(id: string): Promise<DealType> {
        const deal = await this.apiClient.dealGet({ id })
        return mapOpenApiToDeal(deal)
    }

    public async getWithValuations(id: string): Promise<DealWithValuationsType> {
        const dealWithValuations = await this.apiClient.dealWithValuationsGet({ id })
        return mapOpenApiToDealWithValuations(dealWithValuations)
    }

    public async update(id: string, deal: DealType): Promise<ExtractedResponse<DealType>> {
        try {
            return new FetchResponse(mapOpenApiToDeal(await this.apiClient.dealUpdate({ id, dealOpenApi: mapDealToOpenApi(deal) })))
        }
        catch (err) {
            return await extractError(err)
        }
    }

    public async getAllQuestionsForDeal(dealId: string): Promise<DealQuestionType[]> {
        return (await this.apiClient.dealQuestionGetAll({ id: dealId }))
            .map(q => mapOpenApiToDealQuestion(q))
    }

    public async previewPublicationPreview(id: string): Promise<DealPublicationValidationPreview> {
        const preview = await this.apiClient.dealPublicationValidationPreview({ id })
        return mapOpenApiToDealPublicationValidationPreview(preview)
    }

    public async publish(id: string): Promise<ExtractedResponse<DealType>> {
        try {
            return new FetchResponse(mapOpenApiToDeal(await this.apiClient.dealPublish({ id })))
        }
        catch (err) {
            return await extractError(err)
        }
    }

    public async setOutcome(id: string, outcome: DealOutcomeOpenApi): Promise<ExtractedResponse<DealType>> {
        try {
            const res = await this.apiClient.dealSetOutcome({
                id,
                dealSetOutcomeRequestOpenApi: { outcome }
            })
            return new FetchResponse(mapOpenApiToDeal(res))
        }
        catch (err) {
            return await extractError(err)
        }
    }

    public async sendPaymentInstructions(id: string): Promise<ExtractedResponse<void>> {
        try {
            return new FetchResponse(await this.apiClient.dealSendOutPaymentInstructions({ id }))
        }
        catch (err) {
            return await extractError(err)
        }
    }

    public async toggleResolveStatusQuestion(dealId: string, questionId: string): Promise<DealQuestionType> {
        const savedDealQuestion = await this.apiClient.dealQuestionToggleResolveStatus({
            id: dealId,
            questionId: questionId
        })
        return mapOpenApiToDealQuestion(savedDealQuestion)
    }

    public async unpublish(id: string): Promise<ExtractedResponse<DealType>> {
        try {
            return new FetchResponse(mapOpenApiToDeal(await this.apiClient.dealUnpublish({ id })))
        }
        catch (err) {
            return await extractError(err)
        }
    }

    public async getSignedContractsZip(id: string): Promise<Blob> {
        return (await this.apiClient.dealGetSignedContractsZip({ id }))
    }

}