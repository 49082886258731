import React, {FunctionComponent, useContext, useEffect, useState} from "react"
import SectionHeading from "../../../tech/section/section-heading.component"
import {useNavigate, useParams} from "react-router-dom"
import {FetchClient} from "../../../tech/fetch/client"
import FetchContext from "../../../tech/fetch/fetch.context"
import Alert from "../../../tech/alert/alert.component"
import {AlertType} from "../../../tech/alert/type.enum"
import LoadingDots from "../../../tech/loading/dots/dots.component"
import {FileType} from "../file.type"
import FilePreview from "../preview/preview.component"
import styles from "./edit.module.sass"
import FileEditForm from "./form.component"
import {getIdentifierOrDefaultToId} from "../file.util"
import Button from "../../../tech/button/button.component"
import {ButtonStyle} from "../../../tech/button/style.enum"
import {FILE_DELETE} from "../../../paths"

const FileEdit: FunctionComponent = () => {
    let { id } = useParams()
    const navigate = useNavigate()
    const fetchClient = useContext<FetchClient>(FetchContext)
    const [state, setState] = useState<"LOADING" | "LOADED" | "ERROR">("LOADING")
    const [file, setFile] = useState<FileType>()

    useEffect(() => {
        const fetch = async () => {
            try {
                setFile(await fetchClient.fileApi.get(id!))
                setState("LOADED")
            } catch (err) {
                console.error(err)
                setState("ERROR")
            }
        }
        fetch()
    }, [fetchClient, id])

    return (
        <>
            {state === "LOADING" && <LoadingDots/>}
            {state === "LOADED" && file &&  (
                <div className={styles.edit}>
                    <SectionHeading
                        title={`Edit file: ${getIdentifierOrDefaultToId(file)}`}
                        button={<Button
                            title="Delete"
                            style={ButtonStyle.DELETE}
                            onClick={() => navigate(FILE_DELETE(id!))}
                            type="button"
                        />}
                    />
                    <div className={styles.preview}>
                        <div>
                            <FilePreview
                                file={file}
                                maxHeight={400}
                            />
                        </div>
                    </div>
                    <FileEditForm file={file}/>
                </div>
            )}
            {state === "ERROR" && (
                <Alert
                    type={AlertType.ERROR}
                    text="Failed to load file."
                />
            )}
        </>
    )
}

export default FileEdit