import React, {FunctionComponent, useContext, useEffect, useState} from "react"
import tableStyles from "../../../tech/table/table.module.css"
import {DateTime} from "luxon"
import ActionLink from "../../../tech/link/action.component"
import {FileDownloadLinkType, FileType} from "../../file/file.type"
import {FetchClient} from "../../../tech/fetch/client"
import FetchContext from "../../../tech/fetch/fetch.context"
import {DocumentType} from "../document.type"
import LoadingDots from "../../../tech/loading/dots/dots.component"
import Alert from "../../../tech/alert/alert.component"
import {AlertType} from "../../../tech/alert/type.enum"

type DocumentsTableProps = {
    documents: DocumentType[]
}

const DocumentsTable: FunctionComponent<DocumentsTableProps> = ({ documents }) => {
    const fetchClient = useContext<FetchClient>(FetchContext)
    const [state, setState] = useState<"LOADING" | "LOADED" | "ERROR">("LOADING")
    const [fileDownloadLinks, setFileDownloadLinks] = useState<FileDownloadLinkType[]>([])

    useEffect(() => {
        const fetch = async () => {
            try {
                const files = documents.map(d => d.file).filter((f): f is FileType => !!f)
                setFileDownloadLinks(await Promise.all(files.map(f => fetchClient.fileApi.getDownloadLink(f.id))))
                setState("LOADED")
            } catch (err) {
                console.error(err)
                setState("ERROR")
            }
        }
        fetch()
    }, [fetchClient, documents])

    if (state === "LOADING") return <LoadingDots/>
    if (state === "LOADED") {
        if (documents.length === 0) {
            return<>No documents available.</>
        }

        return (
            <div className={tableStyles.tableWrapper}>
                <table className={tableStyles.table}>
                    <thead>
                    <tr>
                        <th>Id</th>
                        <th>Name</th>
                        <th>Dispatched</th>
                        <th>Completed</th>
                        <th>Signed</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {documents.map((document, i) => {
                        const fileLink = fileDownloadLinks.find(l => document.file?.id === l.fileId)?.link
                        return (
                            <tr key={i}>
                                <td>{document.id}</td>
                                <td>{document.name}</td>
                                <td>{DateTime.fromJSDate(document.dispatched).toLocaleString(DateTime.DATETIME_MED)}</td>
                                <td>
                                    {document.completed
                                        ? DateTime.fromJSDate(document.completed).toLocaleString(DateTime.DATETIME_MED)
                                        : "-"}
                                </td>
                                <td>{document.signed ? "signed" : "not yet signed"}</td>
                                <td>
                                    {fileLink && (
                                        <ActionLink
                                            text="Download file"
                                            link={fileLink}
                                            blank
                                        />
                                    )}
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>
        )
    }
    return (
        <Alert
            type={AlertType.ERROR}
            text="Unknown error."
        />
    )
}

export default DocumentsTable