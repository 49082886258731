import React, {FunctionComponent} from "react"
import {DateTime} from "luxon"
import {SubscriptionType} from "./subscription.type"
import {mapSubscriptionTypeToString} from "./subscription.mapper"
import {CurrencyOpenApi} from "../../generated"
import styles from "./summary.module.sass"

type SubscriptionSummaryProps = {
    subscription?: SubscriptionType
}

const SubscriptionSummary: FunctionComponent<SubscriptionSummaryProps> = ({ subscription }) => {
    if (!subscription) {
        return <>-</>
    }

    return (
        <div className={styles.subscription}>
            {mapSubscriptionTypeToString(subscription.type)}
            <small>
                {subscription.price.toLocaleString("en", {
                    currency: CurrencyOpenApi.Sgd,
                    style: 'currency',
                    maximumFractionDigits: 0
                })}
            </small>
            {subscription.cancellation && (
                <div className={styles.cancellation}>
                    <small>
                        Terminates {DateTime.fromJSDate(subscription.cancellation.terminationDate).toLocaleString(DateTime.DATE_MED)}
                    </small>
                </div>
            )}
            <div className={styles.stripeId}>
                Stripe ID: <small>{subscription.stripeId}</small>
            </div>
        </div>
    )
}

export default SubscriptionSummary