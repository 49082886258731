import React, {FunctionComponent, useContext, useEffect} from "react"
import Select from "react-select"
import {Controller, SubmitHandler, useForm} from "react-hook-form"
import FormError from "../../tech/form/error.component"
import {SyndicateType} from "./syndicate.type"
import {InvestorGroupType} from "../investor-group/investor-group.type"
import {UserType} from "../user/user.type"
import FileUploadSelect from "../file/upload-select/upload-select.component"
import {FileSortOpenApi, MimeTypeOpenApi} from "../../generated"
import {FetchClient} from "../../tech/fetch/client"
import FetchContext from "../../tech/fetch/fetch.context"
import {FileType} from "../file/file.type"
import {FileSelectSearchType} from "../file/upload-select/select/select.component"
import formStyles from "../../tech/form/form.module.css"
import {ImageType} from "../../tech/image/image.type"
import {FileUploadSelectFormValueType} from "../file/upload-select/upload-select.type"

type SyndicateFormProps = {
    syndicate?: SyndicateType
    investorGroups: InvestorGroupType[]
    managers: UserType[]
    onSubmit: SubmitHandler<SyndicateType>
    submitText: string
}

const SyndicateForm: FunctionComponent<SyndicateFormProps> = ({
    syndicate,
    investorGroups,
    managers,
    onSubmit,
    submitText
}) => {
    const fetchClient = useContext<FetchClient>(FetchContext)
    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        watch
    } = useForm<SyndicateType>({
        defaultValues: syndicate
    })

    async function fetchFiles(searchType: FileSelectSearchType, searchValue: string): Promise<FileType[]> {
        const result = await fetchClient.fileApi.getAllPaginated(
            FileSortOpenApi.IdentifierAscending,
            {
                id: searchType === "ID" ? searchValue : undefined,
                identifier: searchType === "IDENTIFIER" ? searchValue : undefined,
                mimeTypes: [
                    MimeTypeOpenApi.Jpg,
                    MimeTypeOpenApi.Png,
                    MimeTypeOpenApi.Svg,
                ]
            }
        )
        return result.elements
    }

    useEffect(() => {
        register("logo", {
            required: "Logo is required."
        })
    }, [register])

    return (
        <form
            className={formStyles.form}
            onSubmit={handleSubmit(onSubmit)}
        >
            <input type="hidden" {...register("id")} />

            <label>
                <span>Name</span>
                <input
                    type="text"
                    placeholder="Name"
                    {...register("name", {
                        required: "Name is required.",
                        minLength: {value: 3, message: "Too short."}
                    })}
                />
                <FormError field="name" errors={errors}/>
            </label>

            <div className={formStyles.label}>
                <span>Logo</span>
                <FileUploadSelect
                    accept={["JPG", "PNG", "SVG"]}
                    currentFile={watch("logo")}
                    filesProvider={fetchFiles}
                    setFormValue={(value: FileUploadSelectFormValueType) => setValue("logo", value as ImageType)}
                />
                <FormError field="logo" errors={errors}/>
            </div>


            {investorGroups.length > 0 && (
                <label>
                    <span>Investor groups</span>
                    <Controller
                        name="investorGroups"
                        control={control}
                        render={({field: {onChange, value}}) => {
                            const options = investorGroups.map(investorGroup => ({
                                label: investorGroup.name,
                                value: investorGroup
                            }))
                            const valueIds = value ? value.map(v => v.id) : []
                            return (
                                <Select
                                    options={options}
                                    value={options.filter((o) => valueIds.includes(o.value.id))}
                                    onChange={val => onChange(val.map(v => v.value))}
                                    isMulti
                                />
                            )
                        }}
                        rules={{
                            validate: (groups) => {
                                if (groups === undefined || groups.length < 1) return "At least one investor group is required."
                                return true
                            }
                        }}
                    />
                    <FormError field="investorGroups" errors={errors}/>
                </label>
            )}

            {investorGroups.length > 0 && (
                <label>
                    <span>Managers</span>
                    <Controller
                        name="managers"
                        control={control}
                        render={({field: {onChange, value}}) => {
                            const options = managers.map(manager => ({
                                label: `${manager.email} (${manager.id})`,
                                value: manager
                            }))
                            const valueIds = value ? value.map(v => v.id) : []
                            return (
                                <Select
                                    options={options}
                                    value={options.filter((o) => valueIds.includes(o.value.id))}
                                    onChange={val => onChange(val.map(v => v.value))}
                                    isMulti
                                />
                            )
                        }}
                        rules={{
                            validate: (ms) => {
                                if (ms === undefined || ms.length < 1) return "At least one manager is required."
                                return true
                            }
                        }}
                    />
                    <FormError field="managers" errors={errors}/>
                </label>
            )}

            <button
                type="submit"
                className={formStyles.submitButton}
            >
                {submitText}
            </button>
        </form>
    )
}

export default SyndicateForm