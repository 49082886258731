import React, {FunctionComponent} from "react"
import tableStyles from "../../../tech/table/table.module.css"
import {InvestorType} from "../investor.type"
import {formatDate} from "../../../tech/format/format.util"
import styles from "./kyc-table-cell.module.sass"

type InvestorKycTableCellProps = {
    investor: InvestorType
    onClick?: () => void
}

const InvestorKycTableCell: FunctionComponent<InvestorKycTableCellProps> = ({
    investor,
    onClick
}) => {
    return (
        <td
            onClick={onClick}
            className={tableStyles.cellList}
        >
            <ul>
                <li>AI status
                    declared: <b>{investor.aiStatusDeclared ? "yes" : "no"}</b>
                </li>
                <li>Passport valid
                    till: <b>{formatDate(investor.kyc?.passportValidTill)}</b>
                </li>
                <li>Proof of address valid
                    till: <b>{formatDate(investor.kyc?.proofOfAddressValidTill)}</b>
                </li>
                <li>Proof of AI status
                    available: <b>{investor.kyc?.proofOfAiStatusAvailable ? "yes" : "no"}</b>
                </li>
                <li>
                    Overall:{" "}
                    {isComplete(investor) ? (
                        <strong className={styles.complete}>Complete</strong>
                    ) : (
                        <strong className={styles.incomplete}>Not complete</strong>
                    )}
                </li>
            </ul>
        </td>
    )
}

export default InvestorKycTableCell

function isComplete(investor: InvestorType) {
    return investor.aiStatusDeclared
        && investor.kyc
        && investor.kyc.passportValidTill
        && investor.kyc.passportValidTill >= new Date()
        && investor.kyc.proofOfAddressValidTill
        && investor.kyc.proofOfAddressValidTill >= new Date()
        && investor.kyc.proofOfAiStatusAvailable
}