import React, {FunctionComponent} from "react"
import {DateTime} from "luxon"
import SectionHeading from "../../../tech/section/section-heading.component"
import Button from "../../../tech/button/button.component"
import {ButtonStyle} from "../../../tech/button/style.enum"
import {SYNDICATE_INVITATION_DELETE, SYNDICATE_INVITATION_NEW, SYNDICATE_INVITATION_RESEND} from "../../../paths"
import {useNavigate} from "react-router-dom"
import {SyndicateInvitationType} from "./invitation.type"
import {SyndicateType} from "../syndicate.type"
import tableStyles from "../../../tech/table/table.module.css"
import ActionLink from "../../../tech/link/action.component"

type SyndicateInvitationsOverviewProps = {
    syndicate: SyndicateType
    invitations: SyndicateInvitationType[]
}

const SyndicateInvitationsOverview: FunctionComponent<SyndicateInvitationsOverviewProps> = ({
    syndicate,
    invitations
}) => {
    const navigate = useNavigate()
    return (
        <>
            <SectionHeading
                title="Pending Invitations"
                subTitle={`${invitations.length} pending invitations`}
                button={<Button
                    title="Invite someone"
                    style={ButtonStyle.SECONDARY}
                    onClick={() => navigate(SYNDICATE_INVITATION_NEW(syndicate.id!))}
                    type="button"
                />}
            />
            {invitations.length > 0 ? (
                <div className={tableStyles.tableWrapper}>
                    <table className={tableStyles.table}>
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>Created</th>
                            <th>Email</th>
                            <th>Code</th>
                            <th>Target investor group</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {invitations.map((invitation, i) => (
                            <tr key={i}>
                                <td>
                                    {invitation.id}
                                </td>
                                <td>
                                    {DateTime.fromJSDate(invitation.created!).toLocaleString(DateTime.DATETIME_MED)}
                                </td>
                                <td>
                                    {invitation.email}
                                </td>
                                <td>
                                    {invitation.code}
                                </td>
                                <td>
                                    {invitation.targetInvestorGroup.name}
                                </td>
                                <td>
                                    <ActionLink
                                        text={"Resend"}
                                        link={SYNDICATE_INVITATION_RESEND(syndicate.id!, invitation.id!)}
                                    />
                                    <ActionLink
                                        text={"Delete"}
                                        link={SYNDICATE_INVITATION_DELETE(syndicate.id!, invitation.id!)}
                                    />
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            ) : (
                <>No pending invitations.</>
            )}
        </>
    )
}

export default SyndicateInvitationsOverview