import {FFileOpenApi, FileDownloadLinkOpenApi, FileUpdateOpenApi} from "../../generated"
import {FileDownloadLinkType, FileType, FileUpdateType} from "./file.type"

export function mapOpenApiToFile(file: FFileOpenApi): FileType {
    return {
        id: file.id,
        created: file.created,
        modified: file.modified,
        path: file.path,
        url: file.url,
        type: file.type,
        mimeType: file.mimeType,
        bytes: file.bytes,
        identifier: file.identifier
    }
}

export function mapFileToOpenApi(file: FileType): FFileOpenApi {
    return {
        id: file.id,
        created: file.created,
        modified: file.modified,
        path: file.path,
        url: file.url,
        type: file.type,
        mimeType: file.mimeType,
        bytes: file.bytes,
        identifier: file.identifier
    }
}

export function mapOpenApiToFileDownloadLink(downloadLink: FileDownloadLinkOpenApi): FileDownloadLinkType {
    return {
        fileId: downloadLink.fileId,
        link: downloadLink.link
    }
}

export function mapFileUpdateToOpenApi(update: FileUpdateType): FileUpdateOpenApi {
    return {
        identifier: update.identifier
    }
}