import React, {FunctionComponent, useContext} from "react"
import {Controller, SubmitHandler, useForm} from "react-hook-form"
import {Link} from "react-router-dom"
import formStyles from "../../../tech/form/form.module.css"
import {ManualReconciliationLogType} from "./manual-reconciliation-log.type"
import FormError from "../../../tech/form/error.component"
import {DateTime} from "luxon"
import {ManualReconciliationBankAccountType} from "../account/manual-reconciliation-bank-account.type"
import Select from "react-select"
import AsyncSelect from "react-select/async"
import {InvestmentType} from "../../investment/investment.type"
import {FetchClient} from "../../../tech/fetch/client"
import FetchContext from "../../../tech/fetch/fetch.context"
import {INVESTMENT} from "../../../paths"
import {formatAmountWithCurrency} from "../../../tech/format/format.util"
import styles from "./form.module.sass"

type ManualReconciliationLogFormProps = {
    log?: ManualReconciliationLogType
    manualReconciliationBankAccounts: ManualReconciliationBankAccountType[]
    onSubmit: SubmitHandler<ManualReconciliationLogType>
    submitText: string
}

const ManualReconciliationLogForm: FunctionComponent<ManualReconciliationLogFormProps> = ({
    log,
    manualReconciliationBankAccounts,
    onSubmit,
    submitText
}) => {
    const fetchClient = useContext<FetchClient>(FetchContext)
    const {
        control,
        register,
        handleSubmit,
        formState: { errors }
    } = useForm<ManualReconciliationLogType>({
        defaultValues: log
    })

    const searchInvestments = async (inputValue: string): Promise<{ label: string, value: InvestmentType }[]> => {
        return (await fetchClient.investmentApi.searchForManualReconciliation(inputValue))
            .map(investment => {
                const amount = formatAmountWithCurrency(investment.deal.currency !== investment.currency
                    ? investment.amountWithPlacementFeeAndExchangeFeeAtExchangeRate!
                    : investment.amountWithPlacementFeeAndExchangeFee!, investment.currency!)
                const dealName = investment.deal.name.completeOnboarding
                const person = investment.investor.person
                return {
                    label: `${person?.firstName} ${person?.lastName} (Deal: ${dealName} | Amount: ${amount}) [${investment.id}]`,
                    value: investment
                }
            })
    }

    return (
        <form
            className={formStyles.form}
            onSubmit={handleSubmit(onSubmit)}
        >
            <input type="hidden" {...register("id")} />

            <label>
                <span>Received</span>
                <Controller
                    name="received"
                    control={control}
                    render={({field: {onChange, value}}) => {
                        const isoDate = value !== undefined ? DateTime.fromJSDate(value).toISODate() as string : undefined
                        return (
                            <input
                                type="date"
                                defaultValue={isoDate}
                                onChange={e => onChange(new Date(e.target.value))}
                            />
                        )
                    }}
                    rules={{
                        required: "Received is required."
                    }}
                />
                <FormError field="received" errors={errors}/>
            </label>

            <label>
                <span>Amount</span>
                <input
                    type="number"
                    step={0.01}
                    {...register("amount", {
                        required: "Amount is required.",
                        min: {value: 0, message: "Too small."}
                    })}
                />
                <FormError field="amount" errors={errors}/>
            </label>

            <label>
                <span>Comment</span>
                <textarea
                    {...register("comment")}
                ></textarea>
                <FormError field="comment" errors={errors}/>
            </label>

            {log?.investment ? (
                <div className={styles.investment}>
                    <div>Investment</div>
                    <ul>
                        <li>ID: {log.investment.id}</li>
                        <li>Investor: {log.investment.investor.person?.firstName} {log.investment.investor.person?.lastName}</li>
                        <li>Deal: {log.investment.deal.name.completeOnboarding}</li>
                        <li>Paid: {log.investment.paid ? "yes" : "no"}</li>
                    </ul>
                    <Link to={INVESTMENT(log.investment.id)}>
                        &rarr; Go to investment
                    </Link>
                </div>
            ) : (
                <label>
                <span>Investment</span>
                    <Controller
                        name="investment"
                        control={control}
                        render={({ field: { onChange } }) => {
                            return (
                                <AsyncSelect
                                    placeholder="Search by investor first and/or lastname and/or deal name"
                                    cacheOptions
                                    loadOptions={searchInvestments}
                                    onChange={val => onChange(val?.value)}
                                />
                            )
                        }}
                    />
                </label>
            )}

            <label>
                <span>Manual Reconciliation Bank Account</span>
                <Controller
                    name="bankAccount"
                    control={control}
                    render={({field: {onChange, value}}) => {
                        const options = manualReconciliationBankAccounts.map(account => ({
                            label: `${account.number} - ${account.currency} (${account.holderSinglePurposeVehicle.name}, ${account.bank.name})`,
                            value: account
                        }))
                        return (
                            <Select
                                options={options}
                                value={options.find(u => u.value.id === value?.id)}
                                onChange={val => onChange(val?.value)}
                            />
                        )
                    }}
                />
            </label>

            <button
                type="submit"
                className={formStyles.submitButton}
            >
                {submitText}
            </button>
        </form>
    )
}

export default ManualReconciliationLogForm