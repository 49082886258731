import React, {FunctionComponent} from "react"
import {Control, FieldErrors, useFieldArray, UseFormRegister} from "react-hook-form"
import {DealType} from "../deal/deal.type"
import FormError from "../../tech/form/error.component"
import styles from "./tiles.module.sass"

type DealTilesProps = {
    control: Control<DealType, any>
    errors: FieldErrors<DealType>
    register: UseFormRegister<DealType>
}

const DealTiles: FunctionComponent<DealTilesProps> = ({ control, errors, register }) => {
    const { fields, append, remove } = useFieldArray({ name: "tiles", control })

    const add = () => append({ title: "", text: "", order: 0 })

    const del = (i: number) => remove(i)

    return (
        <div className={styles.tiles}>
            {fields.sort((a, b) => a.order - b.order).map((item, index) => (
                <div
                    className={styles.tile}
                    key={item.id}
                >
                    <label>
                        <span>Title</span>
                        <input
                            {...register(
                                `tiles.${index}.title`,
                                {
                                    required: "Title is required.",
                                    minLength: { value: 2, message: "Too short." }
                                }
                            )}
                            type="text"
                        />
                        <FormError field={`tiles.${index}.title`} errors={errors} />
                    </label>
                    <label>
                        <span>Order</span>
                        <input
                            {...register(
                                `tiles.${index}.order`,
                                {
                                    required: "Order is required."
                                }
                            )}
                            type="number"
                        />
                        <FormError field={`tiles.${index}.order`} errors={errors} />
                    </label>
                    <label>
                        <span>Text</span>
                        <textarea
                            {...register(
                                `tiles.${index}.text`,
                                {
                                    required: "Text is required."
                                }
                            )}
                        />
                        <FormError field={`tiles.${index}.text`} errors={errors} />
                    </label>
                    <div className={styles.actions}>
                        <button
                            type="button"
                            onClick={() => del(index)}
                        >
                            Delete
                        </button>
                    </div>
                </div>
            ))}
            <div className={`${styles.actions} ${styles.add}`}>
                <button
                    type="button"
                    onClick={add}
                >
                    Add
                </button>
            </div>
        </div>
    )
}

export default DealTiles