import React, {FunctionComponent, useState} from "react"
import {
    FieldArrayWithId,
    FieldErrors,
    UseFieldArrayRemove,
    UseFormGetValues,
    UseFormRegister,
    UseFormSetValue
} from "react-hook-form"
import {DealType} from "../deal.type"
import formStyles from "../../../tech/form/form.module.css"
import FormError from "../../../tech/form/error.component"
import {DealFeeStructureType} from "../fee/deal-fee-structure.type"
import styles from "../select.module.sass"

type DealInvestorGroupSelectSingleProps = {
    field: FieldArrayWithId<DealType, "investorGroups">
    index: number
    errors: FieldErrors<DealType>
    register: UseFormRegister<DealType>
    remove: UseFieldArrayRemove
    getValues: UseFormGetValues<DealType>
    setValue: UseFormSetValue<DealType>
}

const DealInvestorGroupSelectSingle: FunctionComponent<DealInvestorGroupSelectSingleProps> = ({
    field,
    index,
    errors,
    register,
    remove,
    getValues,
    setValue
}) => {
    const [customFeeStructure, setCustomFeeStructure] = useState<boolean>(
        prefillCustomFeeStructure(getValues(`investorGroups.${index}.feeStructure`))
    )

    const toggleCustomFeeStructure = () => {
        if (customFeeStructure) {
            // @ts-ignore
            setValue(`investorGroups.${index}.feeStructure`, undefined)
        }
        setCustomFeeStructure(!customFeeStructure)
    }

    const del = (i: number) => remove(i)

    return (
        <div className={styles.box}>
            <input type="hidden" {...register(`investorGroups.${index}.id`,)} />
            <input type="hidden" {...register(`investorGroups.${index}.name`,)} />
            <input type="hidden" {...register(`investorGroups.${index}.description`,)} />
            <div className={styles.boxTitle}>
                <div>
                    <strong>{field.name}</strong>
                </div>
                <div className={styles.delete}>
                    <button
                        type="button"
                        onClick={() => del(index)}
                    >
                        Delete
                    </button>
                </div>
            </div>
            <div className={formStyles.checkbox}>
                <div className={formStyles.checkboxInner}>
                    <input
                        id={`customFeeStructure-${field.id}`}
                        type="checkbox"
                        checked={customFeeStructure}
                        onChange={toggleCustomFeeStructure}
                    />
                    <label htmlFor={`customFeeStructure-${field.id}`}>
                        Custom fee structure?
                    </label>
                </div>
                <FormError field="confirmed" errors={errors} />
            </div>

            {customFeeStructure && (
                <>
                    <label>
                        <span>Setup fee (%)</span>
                        <input
                            type="number"
                            step={0.05}
                            defaultValue={1.5}
                            {...register(
                                `investorGroups.${index}.feeStructure.placementFee`,
                                {
                                    validate: (placementFee) => {
                                        if (customFeeStructure && placementFee === undefined) return "Setup fee is required."
                                        return true
                                    },
                                    min: { value: 0, message: "Must not be negative." },
                                    max: { value: 100, message: "Must not be greater than 100 percent." }
                                }
                            )}
                        />
                        <FormError field={`investorGroups.${index}.feeStructure.placementFee`} errors={errors} />
                    </label>
                    <label>
                        <span>Management fee (%)</span>
                        <input
                            type="number"
                            step={0.05}
                            defaultValue={0}
                            {...register(
                                `investorGroups.${index}.feeStructure.managementFee`,
                                {
                                    validate: (placementFee) => {
                                        if (customFeeStructure && placementFee === undefined) return "Management fee is required."
                                        return true
                                    },
                                    min: { value: 0, message: "Must not be negative." },
                                    max: { value: 100, message: "Must not be greater than 100 percent." }
                                }
                            )}
                        />
                        <FormError field={`investorGroups.${index}.feeStructure.managementFee`} errors={errors} />
                    </label>
                    <label>
                        <span>Carry (%)</span>
                        <input
                            type="number"
                            step={0.05}
                            defaultValue={20}
                            {...register(
                                `investorGroups.${index}.feeStructure.carry`,
                                {
                                    validate: (placementFee) => {
                                        if (customFeeStructure && placementFee === undefined) return "Carry is required."
                                        return true
                                    },
                                    min: { value: 0, message: "Must not be negative." },
                                    max: { value: 100, message: "Must not be greater than 100 percent." }
                                }
                            )}
                        />
                        <FormError field={`investorGroups.${index}.feeStructure.carry`} errors={errors} />
                    </label>
                </>
            )}
        </div>
    )
}

export default DealInvestorGroupSelectSingle

function prefillCustomFeeStructure(feeStructure: DealFeeStructureType | undefined): boolean {
    return feeStructure !== undefined
        && feeStructure.placementFee !== undefined
        && feeStructure.managementFee !== undefined
        && feeStructure.carry !== undefined
}